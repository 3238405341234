<div class="flex-login-container">
    <div class="login-panel clearfix" style="max-height: 100%;">
        <div class="login-container">
            <div class="login-form-panel full">
                <div class="page-loading" *ngIf="loading">
                    <span>
                        <span></span>
                    </span>
                </div>
                <div class="accordion-form" class="clearfix" style="padding: 0 5px">
                    <mat-accordion multi>
                        <mat-expansion-panel hideToggle [expanded]="personalOpenState"
                            (opened)="setPanelState('personal')">
                            <mat-expansion-panel-header class="mat-expansion-header">
                                <mat-panel-title>Personal</mat-panel-title>
                            </mat-expansion-panel-header>
                            <form [formGroup]="personalForm">
                                <div class="selector columns small-12">
                                    <mat-label>First Name:</mat-label>
                                    <input matInput type="text" formControlName="firstName" placeholder="First Name"
                                        autocomplete="given-name" required />
                                </div>
                                <div class="selector columns small-12">
                                    <mat-label>Last Name:</mat-label>
                                    <input matInput type="text" formControlName="lastName" placeholder="Last Name"
                                        autocomplete="family-name" required />
                                </div>
                                <div class="selector columns small-12">
                                    <mat-label>Employee Number: </mat-label>
                                    <span class="has-tip fa fa-info-circle"
                                        matTooltip="Your Employee / Pension Number can be found on your Partner discount card and payslip"></span>
                                    <input matInput type="text" formControlName="employeeNumber"
                                        placeholder="Employee Number" required />
                                </div>
                                <div class="selector columns small-12">
                                    <div class="small-6 small-offset-6">
                                        <button [disabled]="personalForm.invalid" class="btn btn-default max-width"
                                            (click)="setPanelState('location')">
                                            Location <span class="fa fa-chevron-circle-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle [expanded]="locationOpenState"
                            (opened)="setPanelState('location')">
                            <mat-expansion-panel-header class="mat-expansion-header">
                                <mat-panel-title>Location</mat-panel-title>
                            </mat-expansion-panel-header>
                            <form [formGroup]="locationForm">
                                <div class="selector columns small-12">
                                    <mat-label>Branch number: </mat-label>
                                    <span class="has-tip fa fa-info-circle"
                                        matTooltip="Your Branch number consists of 3 digits. If you are a retired partner, please confirm this by selecting the box below"></span>
                                    <input matInput type="text" formControlName="branchNumber" [disabled]="retired"
                                        placeholder="Branch Number" [required]="!retired" />
                                </div>
                                <div class="selector columns small-12">
                                    <mat-label>Retired Partner: </mat-label>
                                    <input class="checkbox" type="checkbox" formControlName="retiredPartner"
                                        (change)="updateRetired($event)" />
                                </div>
                                <div class="selector columns small-12">
                                    <div class="small-6 align-left">
                                        <button class="btn btn-default max-width" (click)="setPanelState('personal')">
                                            Personal <span class="fa fa-chevron-circle-up"></span>
                                        </button>
                                    </div>
                                    <div class="small-6 align-left">
                                        <button class="btn btn-default max-width" (click)="setPanelState('preferences')"
                                            [disabled]="locationForm.invalid">
                                            Preferences <span class="fa fa-chevron-circle-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle [expanded]="preferencesOpenState"
                            (opened)="setPanelState('preferences')">
                            <mat-expansion-panel-header class="mat-expansion-header">
                                <mat-panel-title>Preferences</mat-panel-title>
                            </mat-expansion-panel-header>
                            <form [formGroup]="preferencesForm">
                                <div class="selector columns small-12">
                                    <p>We would like to send you information about products, services,
                                        tickets and events of ours and other companies that we offer
                                        benefits through, which may be of interest to you. You have a right
                                        at any time to stop us from contacting you for marketing purposes.</p>
                                    <mat-label><input class="checkbox" type="checkbox"
                                            formControlName="receiveMarketingEmails" />
                                        Yes, I would like
                                        to receive information about Partnership events, flash sales,
                                        Partner extravaganzas, Club news, offers, tickets and so much
                                        more...</mat-label>
                                </div>
                                <div class="selector columns small-12">
                                    <div class="small-6 align-left">
                                        <button class="btn btn-default max-width" (click)="setPanelState('location')">
                                            Location <span class="fa fa-chevron-circle-up"></span>
                                        </button>
                                    </div>
                                    <div class="small-6 align-left">
                                        <button class="btn btn-default max-width" (click)="setPanelState('account')"
                                            [disabled]="preferencesForm.invalid">
                                            Account <span class="fa fa-chevron-circle-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle [expanded]="accountOpenState"
                            (opened)="setPanelState('account')">
                            <mat-expansion-panel-header class="mat-expansion-header">
                                <mat-panel-title>Account</mat-panel-title>
                            </mat-expansion-panel-header>
                            <form [formGroup]="accountForm">
                                <div class="selector columns small-12">
                                    <mat-label>Email: </mat-label>
                                    <input matInput id="email" type="email" formControlName="email" placeholder="Email"
                                        autocomplete="email" required />
                                </div>
                                <div class="selector columns small-12">
                                    <mat-label>Password: </mat-label>
                                    <input matInput id="password" type="password" formControlName="password"
                                        placeholder="Password" autocomplete="new-password" required />
                                </div>
                                <div class="selector columns small-12">
                                    <mat-label>Confirm Password: </mat-label>
                                    <input matInput id="passwordConfirm" type="password"
                                        formControlName="passwordConfirm" placeholder="Confirm Password" required />
                                </div>
                                <div class="selector columns small-12">
                                    <p>
                                        By registering to this website you agree to the following
                                        <a target="_blank"
                                            href="https://jlpleisurebenefits.zendesk.com/hc/articles/115002889609">Terms
                                            &amp; Conditions</a>
                                    </p>
                                </div>
                                <div class="selector columns small-12">
                                    <div class="small-6 align-left">
                                        <button class="btn btn-default max-width"
                                            (click)="setPanelState('preferences')">
                                            Preferences <span class="fa fa-chevron-circle-up"></span>
                                        </button>
                                    </div>
                                    <div class="small-6 align-left">
                                        <button class="btn btn-default max-width"
                                            [disabled]="accountForm.invalid || personalForm.invalid || locationForm.invalid || preferencesForm.invalid"
                                            (click)="register()">
                                            Register
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="register-panel">
                <div class="contents">
                    <h2>Partner registration</h2>
                    <div class="text-container">
                        <p class="bottom-text">
                            Already registered?
                            <button class="button button-green" [routerLink]="['/Login']">
                                Sign in now
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>