import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'extra-info-content',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})

export class ExtraInfoContentModalComponent implements OnInit {
    public item;

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit(): void {
      this.item = this.data;
    }


  removeFileExtension(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }
  }
  