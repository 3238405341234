<div class="flex-login-container">
    <div class="login-panel clearfix">
        <div class="login-container">
            <div class="login-form-panel">
                <div class="contents" *ngIf="!complete">
                    <div class="page-loading" *ngIf="loading">
                        <span>
                            <span></span>
                        </span>
                    </div>
                    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="selector">
                            <div class="row">
                                <div class="small-12">
                                    <label for="email">Email:
                                        <input type="email" id="email" formControlName="email" placeholder="Email"
                                            autocomplete="email" required />
                                    </label>
                                    <div *ngIf="form.get('email').invalid && form.get('email').touched">
                                        <small *ngIf="form.get('email').errors.required">Email is required.</small>
                                        <small *ngIf="form.get('email').errors.email">Invalid email format.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="selector">
                            <div class="row">
                                <div class="small-12">
                                    <label for="employeeNumber">Employee number:
                                        <input type="text" id="employeeNumber" formControlName="employeeNumber"
                                            placeholder="Employee number" required />
                                    </label>
                                    <div
                                        *ngIf="form.get('employeeNumber').invalid && form.get('employeeNumber').touched">
                                        <small *ngIf="form.get('employeeNumber').errors.required">Employee number is
                                            required.</small>
                                        <small *ngIf="form.get('employeeNumber').errors.minlength">Employee number
                                            must
                                            be at least 8 characters long.</small>
                                        <small *ngIf="form.get('employeeNumber').errors.numeric">Employee number
                                            must be
                                            a number.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button [disabled]="form.invalid" type="submit">
                            Submit
                        </button>
                    </form>
                </div>
                <div class="contents" *ngIf="complete">
                    <p>
                        An email has been sent to {{ emailAddress }} with instructions
                        on resetting your password. Please check your Spam/Junk folders in
                        case the email has been sent there. The link on the reset email is
                        valid for 24hrs, after this time you will need to request another
                        password reset.
                    </p>
                </div>
            </div>
            <div class="register-panel">
                <div class="contents">
                    <h2>Having trouble logging in?</h2>
                    <div class="text-container main-text">
                        <p>Fill in your details to reset your password.</p>
                    </div>
                    <div class="text-container">
                        <p class="bottom-text">
                            Already registered?
                            <button class="button button-green" [routerLink]="['/Login']">
                                Sign in now
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>