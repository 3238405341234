import environment from '../../../environment';

const {settings} = environment;

config.$inject = [
  '$compileProvider',
  '$locationProvider',
  '$sceDelegateProvider',
  '$httpProvider',
  'cfpLoadingBarProvider',
];

export default function config(
  $compileProvider,
  $locationProvider,
  $sceDelegateProvider,
  $httpProvider,
  cfpLoadingBarProvider
) {
  cfpLoadingBarProvider.includeSpinner = false;
  cfpLoadingBarProvider.loadingBarTemplate = `
        <div class="page-loading">
            <span>
                <span></span>
            </span>
        </div>`;

  $compileProvider.aHrefSanitizationWhitelist(
    /^\s*(https?|ftp|mailto|tel|file|data):/
  );

  //To set up #! mode
  $locationProvider.html5Mode(true);

  $httpProvider.interceptors.push('ErrorInterceptor');
  $httpProvider.interceptors.push('ContentInterceptor');
  $httpProvider.interceptors.push('AuthInterceptor');

  $httpProvider.defaults.useXDomain = true;
  $httpProvider.defaults.withCredentials = true;

  delete $httpProvider.defaults.headers.common['X-Requested-With'];

  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    settings.bookingUrl + '/**',
  ]);
}
