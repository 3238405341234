<div class="panel" [ngClass]="{'active-panel' : activePanel.value === 'card' || !activePanel}">
    <div class="panel-heading">
        <h4 class="panel-title">
            Card Details
            <span class="fa fa-spin fa-circle-o-notch" style="float: right; overflow: hidden"></span>
        </h4>
    </div>
    <div class="panel-body wp-test-cards" *ngIf="showCards">
        <h1 (click)="showCards = !showCards" (keypress)="showCards = !showCards">
            WorldPay Test Cards
            <span class="fa" [ngClass]="{'fa-chevron-down': !showCards, 'fa-chevron-up': showCards}"></span>
        </h1>
        <div *ngIf="showCards">
            <dl>
                <dt>Mastercard</dt>
                <dd>5555555555554444</dd>
                <dd>5454545454545454</dd>
                <dt>Visa</dt>
                <dd>4444333322221111</dd>
                <dd>4911830000000</dd>
                <dd>4917610000000000</dd>
                <dt>Visa Debit</dt>
                <dd>4462030000000000</dd>
                <dd>4917610000000000003</dd>
            </dl>
        </div>
    </div>
    <div class="panel-body" id="worldpay-card-details"></div>
</div>