import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import environment from '../../environment/index.js';
import {AuthService} from './auth.service';
// import { PageEvent } from '@angular/material/paginator';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
@Injectable()
export class ApiService {
  public projectFormFields = new BehaviorSubject<any>(null);
  api = environment.settings.awsUrl;
  private messageResponseTime = 10000;
  private messageMsPerChar = 500;

  constructor(
    private readonly Http: HttpClient,
    private readonly auth: AuthService,
    private SnackBar: MatSnackBar
  ) {}

  async unauthenticatedPost(endpoint, body, endpointUrl?: string) {
    endpointUrl = endpointUrl || this.api;
    const url = `${endpointUrl}/${endpoint}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.Http.post(url, body, {
      headers,
      observe: 'response',
    }).toPromise();
  }

  async unauthenticatedPut(endpoint, body, endpointUrl?: string) {
    endpointUrl = endpointUrl || this.api;
    const url = `${endpointUrl}/${endpoint}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.Http.put(url, body, {headers}).toPromise();
  }

  async unauthenticatedGet(endpoint) {
    return await this.Http.get(endpoint).toPromise();
  }

  post(endpoint, body) {
    return this.request(endpoint, 'POST', body);
  }
  put(endpoint, body) {
    return this.request(endpoint, 'PUT', body);
  }

  patch(endpoint, body) {
    return this.request(endpoint, 'PATCH', body);
  }

  get(endpoint: string) {
    return this.request(endpoint);
  }
  delete(endpoint) {
    return this.request(endpoint, 'DELETE');
  }

  public getWithoutPageEventFilter(
    endpoint: string,
    filter?: {},
    endpointUrl?: string
  ) {
    const method = 'GET';
    endpointUrl = endpointUrl || this.api;
    return this.request(`${endpointUrl}/${endpoint}`, method, {
      ...filter,
    });
  }

  //   public getWithPageEventFilter(
  //     endpoint: string,
  //     PageEvent: PageEvent,
  //     filter?: {},
  //     endpointUrl?: string
  //   ) {
  //     const method = 'GET';
  //     const { pageIndex = 0, pageSize = 24 } = PageEvent || {};
  //     endpointUrl = endpointUrl || this.api;
  //     return this.request(`${endpointUrl}/${endpoint}`, method, {
  //       pageIndex,
  //       pageSize,
  //       ...filter,
  //     });
  //   }

  //   public getWithPageEvent(
  //     endpoint: string,
  //     PageEvent: PageEvent,
  //     endpointUrl?: string
  //   ) {
  //     const method = 'GET';
  //     endpointUrl = endpointUrl || this.api;

  //     if (!!PageEvent) {
  //       if (endpoint.includes('page-index')) {
  //         endpoint = endpoint.replace('/page-size/24/page-index/0', '');
  //       }

  //       endpoint += `/page-size/${PageEvent.pageSize}/page-index/${PageEvent.pageIndex}`;
  //     }
  //     return this.request(`${endpointUrl}/${endpoint}`, method);
  //   }

  //   delete(endpoint, endpointUrl?: string, body?) {
  //     endpointUrl = endpointUrl || this.api;
  //     return this.request(`${endpointUrl}/${endpoint}`, 'DELETE', body);
  //   }

  private async request(
    endpoint: string,
    method = 'GET',
    params?: {}
  ): Promise<any> {
    const requestLocation = window.location.href;
    const options = this.httpOptions(method, endpoint, {...params});

    const url = endpoint;

    return this.Http.request(method, url, options)
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(e => {
        switch (e.status) {
          case 401:
            if (!requestLocation.includes('Forgotten')) {
              if (!requestLocation.includes('Login')) {
                localStorage.setItem('loginRedirect', requestLocation);
              }
              this.auth.logout();
            }
            break;
          default:
            this.handleError(e);
            break;
        }

      });
  }

  private httpOptions(
    method: string,
    endpoint,
    params?: {[key: string]: string}
  ) {
    const optionKey = method === 'GET' ? 'params' : 'body';
    const token = endpoint.includes('json')
      ? this.auth.getLeisureToken()
      : this.auth.getToken();

    const options = Object.keys(params).reduce((response, key: string) => {
      if (params[key] !== null) {
        response[key] = params[key];
      }

      return response;
    }, {});

    return {
      headers: {Authorization: `${token}`},
      [optionKey]: options,
    };
  }

  public handleError(message: any = 'An error occurred.'): Promise<void> {
    throw message;
  }
}
