<div class="page-loading" *ngIf="loading">
  <span>
    <span></span>
  </span>
</div>
<div class="order-confirmation print" *ngIf="!loading && status">
  <div class="order" [ngClass]="status.orderStatus">
    <div class="order-header">
      <img [alt]="status.orderStatus" [src]="status.statusImg" *ngIf="true" />
      <h1>{{ status.statusTitle }}</h1>
    </div>
    <div class="order-main">
      <h2>{{ status.orderTitle }}</h2>
      <h3 id="order-{{ orderId }}">Order Number: {{ orderId }}</h3>
      <section *ngIf="order">
        <p>{{ order.item.title }}</p>
        <p *ngIf="order.item.type == 'ballot' || order.item.type == 'ticket'">
          {{ order.item.display_location
          }}<span> - {{ order.item.display_date }}</span>
        </p>
        <p class="status-text">{{ status.statusText }}</p>
        <table aria-describedby="order-{{ orderId }}">
          <thead>
            <tr>
              <th scope="col">Quantity</th>
              <th scope="col">Price type</th>
              <th scope="col" class="hide-mobile">Price</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of order.items">
              <tr *ngFor="let option of item.options | keyvalue">
                <td>{{ option.value.qty }}</td>
                <td>{{ option.value.label }}</td>
                <td class="hide-mobile">
                  {{
                    option.value.price !== 0
                      ? (option.value.price / 100 | currency : '£')
                      : 'Free'
                  }}
                </td>
                <td>
                  {{
                    option.value.total != 0
                      ? (option.value.total / 100 | currency : '£')
                      : 'Free'
                  }}
                </td>
              </tr>
            </ng-container>
            <tr>
              <td></td>
              <td></td>
              <td class="hide-mobile">Total</td>
              <td class="top-border">
                <strong>
                  {{
                    order.total != 0
                      ? (order.total / 100 | currency : '£')
                      : 'Free'
                  }}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="addresses">
          <div class="billing-address" *ngIf="order.billingAddress.postCode">
            <h5>Billing Address</h5>
            <ul>
              <li>{{ order.billingAddress.address1 }}</li>
              <li>{{ order.billingAddress.address2 }}</li>
              <li>{{ order.billingAddress.city }}</li>
              <li>{{ order.billingAddress.county }}</li>
              <li>{{ order.billingAddress.state }}</li>
              <li>{{ order.billingAddress.postCode }}</li>
              <li>{{ order.billingAddress.country }}</li>
            </ul>
          </div>
          <div
            *ngIf="order.item.delivery_method === 'post'"
            class="delivery-address"
          >
            <h5>Delivery Address</h5>
            <ul>
              <li>{{ order.shippingAddress.address1 }}</li>
              <li>{{ order.shippingAddress.address2 }}</li>
              <li>{{ order.shippingAddress.city }}</li>
              <li>{{ order.shippingAddress.county }}</li>
              <li>{{ order.shippingAddress.state }}</li>
              <li>{{ order.shippingAddress.postCode }}</li>
              <li>{{ order.shippingAddress.country }}</li>
            </ul>
          </div>
        </div>
      </section>
      <section *ngIf="statusType == 'statusError'">
        <p class="status-text">
          There has been a problem with your order<br />
          please <a [href]="mailTo">contact</a> Partner Choice for further
          details.
        </p>
      </section>
    </div>
    <div class="order-footer" *ngIf="order?.item">
      <p>
        <span *ngIf="orderStatus === 'success' || orderStatus === 'pending'"
          >Thank you for your payment.</span
        >
        For {{ order.item.type }} information or terms and conditions please
        click
        <a [href]="order.item.terms_and_conditions">here</a>
      </p>
    </div>
  </div>
</div>
