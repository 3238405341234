<div id="modal">
  <div >
    <div class="modal-container">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span class="fa fa-fw fa-times"></span>
        </button>
      </div>
        <iframe
          [src]="video"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          title="JLP Sailing Club"
        ></iframe>
     
    </div>
  </div>
</div>
