<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="user" class="form">
  <section mat-dialog-title>
    <button mat-dialog-close mat-icon-button matTooltip="Close.">
      <mat-icon>close</mat-icon>
    </button>
    <h3>Edit email notifications</h3>
  </section>
  <section mat-dialog-content>
    <p>
      We would like to send you information about products, services, tickets
      and events of ours and other companies that we offer benefits through,
      which may be of interest to you. You have a right at any time to stop us
      from contacting you for marketing purposes.
    </p>
    <input
      *ngIf="user.features.mailchimp?.webhook"
      type="checkbox"
      (click)="mailchimpPopup()"
    />

    <input
      type="checkbox"
      *ngIf="!user.features?.mailchimp?.webhook"
      formControlName="receive_marketing_emails"
    />
    Yes, I would like to receive information about Partnership events, flash
    sales, Partner extravaganzas, Club news, offers, tickets and so much more...
  </section>
  <section align="end" mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      matTooltip="Cancel and close."
      type="button"
    >
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
    <button
      [disabled]="!form.valid || loading"
      mat-button
      matTooltip="Save"
      type="submit"
    >
      <mat-icon>save</mat-icon>
      Confirm
    </button>
  </section>
</form>
