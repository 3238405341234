import { ApiService } from "./api.service";
import environment from '../../environment';
import { Injectable } from "@angular/core";

@Injectable()
export class TicketsService {
    constructor(protected Api: ApiService) {}

    public load(listingId) {
        return this.Api.get(
            `${environment.settings.registeredAwsUrl}/listings/${listingId}/tickets`
          ).then(({data}) => {
            return data;
          });
    }
}