<div class="flex-login-container">
  <div class="login-panel">
    <div class="login-container">
      <div class="login-form-panel">
        <div class="page-loading" *ngIf="loading">
          <span>
            <span></span>
          </span>
        </div>
        <div class="contents">
          <h2>Partner sign in</h2>

          <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="selector">
              <div class="row">
                <div class="small-12">
                  <label for="email">Email:
                    <input type="email" formControlName="email" id="email" autocomplete="email" required />
                  </label>
                </div>
              </div>
            </div>
            <div class="selector">
              <div class="row">
                <div class="small-12">
                  <label for="password">Password:
                    <input type="password" formControlName="password" id="password" autocomplete="new-password"
                      required />
                  </label>
                </div>
              </div>
            </div>
            <button [disabled]="form.invalid" type="submit" class="btn btn-default">
              SIGN IN
            </button>
            <p id="warning" class="form-error"></p>
            <p class="forgotten-link bottom-text" [routerLink]="['/forgotten-password']" style="cursor: pointer;">
              Forgotten your password?
            </p>
          </form>
        </div>
      </div>
      <div class="register-panel">
        <div class="contents">
          <h2>Welcome to Partner Choice</h2>
          <h5>Our exclusive Partner-only website!</h5>

          <div class="text-container main-text">
            <p>
              This is your portal to health & wellbeing and a place to discover
              all of the latest deals and delights we have just for Partners.
            </p>
          </div>
          <button class="button button-green" [routerLink]="['/register']">
            REGISTER
          </button>
          <div class="text-container">
            <p class="bottom-text">
              <a href="https://www.jlpjobs.com/" target="_blank">Not a Partner? Explore how to become one.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>