<div class="panel" [ngClass]="{'active-panel': activePanel.value === 'options'}">
  <div class="panel-heading">
    <h4 class="panel-title">
      Ticket Options
      <span
        class="fa fa-check-square-o"
        [ngClass]="{hide: !status.billingSet}"
      ></span>
    </h4>
  </div>
  <div class="panel-body">
    <section [formGroup]="pickOptionsForm" [ngClass]="{'panel callout' : tooManyTickets}">
      <section class="prices" *ngFor="let price of variant.prices">
        <div class="row">
          <div class="small-12 columns">
            <div class="row" [ngClass]="{'error' : errors[price.slug] || tooManyTickets}">
              <div class="small-4 columns">
                <label class="right inline" [for]="price.slug">
                  {{ price.label }} at {{ !!price.price && (price.price / 100 | currency : 'GBP' : 'symbol' : '1.2-2')
                  || 'Free' }}
                </label>
              </div>
              <div class="small-8 columns">
                <input type="number" pattern="^\d+$" [formControlName]="price.slug" [id]="price.slug" min="0"
                  (change)="updateTotal()" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="supplements" *ngFor="let supplement of variant.supplements">
      <div class="row">
        <div class="small-12 columns">
          <div class="row" [ngClass]="{'error' : errors[supplement.slug] || tooManySupplements}">
            <div class="small-4 columns">
              <label class="right inline" [for]="supplement.slug">
                {{ supplement.label }} at {{ !!supplement.price && (supplement.price / 100 | currency : 'GBP' : 'symbol'
                : '1.2-2') || 'Free' }}
              </label>
            </div>
            <div class="small-8 columns">
              <input type="number" pattern="^\d+$" [formControlName]="supplement.slug" [id]="supplement.slug" min="0"
                (change)="updateTotal()" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <p *ngIf="variant.tickets_per_partner"
      [ngClass]="{'error' : total.tickets > variant.tickets_per_partner, 'info' : total.tickets <= variant.tickets_per_partner}">
      You are only allowed {{ variant.tickets_per_partner }} ticket{{ variant.tickets_per_partner > 1 ? 's' : '' }} per
      Partner
    </p>
    <p class="panel callout warning" *ngIf="showHurry">
      There {{ variant.stock_quantity == 1 ? 'is' : 'are' }} only {{ variant.stock_quantity }} left to purchase, be
      quick before they're all gone!
    </p>
    <checkout-terms [termsUrl]="tsAndCs" (onAgree)="terms = true" (onDisagree)="terms = false"
      (close)="close()"></checkout-terms>
    <div class="full-width">
      <section class="price">
        {{ !isFree && (total.cost / 100 | currency : 'GBP' : 'symbol' : '1.2-2') || 'Free' }}
      </section>
      <button class="button accordion-toggle" (click)="addOptions()" [disabled]="disabled()">
        Add Tickets
      </button>
    </div>
  </div>
</div>
