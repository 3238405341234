import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';

@Injectable()
export class LoginService {
  constructor(private Api: ApiService) {}

  public async awsLogin(email, password): Promise<any> {
    const response = await this.Api.unauthenticatedPost(
      'login',
      {User: {password, email}},
      `${environment.settings.loginAwsUrl}`
    );
    return response?.body;
  }

  public async leisureLogin(email, password): Promise<any> {
    const response = await this.Api.unauthenticatedPost(
      'json/Login',
      {User: {email, password}},
      `${environment.settings.leisureUrl}`
    );
    const authorizationHeader = response?.headers.get('Authorization');
    return {data: response?.body, authorizationHeader};
  }
}
