<div class="login-panel clearfix">
    <div class="reset-panel full row">
        <h2>Reset your password</h2>

        <div class="page-loading" *ngIf="loading">
            <span>
                <span></span>
            </span>
        </div>
        <form [formGroup]="form" class="clearfix" (ngSubmit)="onSubmit()" *ngIf="user.id">
            <p class="alert-box warning radius" data-ng-if="reset.errorFields().length && reset.display.errors">
            </p>
            <div class="row">
                <div class="small-10 small-offset-1 columns">
                    <h3>Hi {{ user.first_name }} {{ user.last_name }}</h3>
                    <p>Fill in your details below to reset your password.</p>
                </div>
            </div>
            <div class="row">
                <div class="small-10 small-offset-1 columns">
                    <p>
                        <span class="fa fa-key"></span> It's a good idea to use a strong
                        password that you don't use elsewhere
                    </p>
                </div>
            </div>

            <div class="selector">
                <div class="row">
                    <div class="small-10 small-offset-1 columns">
                        <label for="password">Password:
                            <input id="password" type="password" formControlName="password" placeholder="Password"
                                required />
                        </label>
                    </div>
                </div>
            </div>

            <div class="selector">
                <div class="row">
                    <div class="small-10 small-offset-1 columns">
                        <label for="passwordConfirm">Confirm password:
                            <input id="passwordConfirm" type="password" formControlName="passwordConfirm"
                                placeholder="Confirm password" required />
                        </label>
                        <div *ngIf="form.get('passwordConfirm').invalid && form.get('passwordConfirm').touched">
                            <small *ngIf="form.get('passwordConfirm').errors.passwordMismatch">Password don't
                                match.</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="selector">
                <div class="row">
                    <div class="small-4 small-offset-7 columns">
                        <button [disabled]="form.invalid" type="submit">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="!user.id">
            <div class="row">
                <div class="small-10 small-offset-1 columns">
                    <p>There has been an issue with the link you used</p>
                    <p>
                        Please check the link, you have used, either copy and paste or click
                        through.
                    </p>

                    <p class="button" [routerLink]="['/Login']">Login</p>
                </div>
            </div>
        </div>
    </div>
</div>