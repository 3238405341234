import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'video-modal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class VideoModalComponent {
  public video;
  public form: FormGroup;
  public loading = false;

  constructor(
    protected dialogRef: MatDialogRef<VideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    Sanitizer: DomSanitizer,

  ) {
    this.video = Sanitizer.bypassSecurityTrustResourceUrl(data.src) ;
  }

  ngOnInit() {}

  public close () {
    this.dialogRef.close();
  }
}
