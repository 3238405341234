<div class="modal">
  <section mat-dialog-title>
    <button mat-dialog-close mat-icon-button matTooltip="Close.">
      <mat-icon>close</mat-icon>
    </button>
    <h3>{{ item.title }}</h3>
  </section>
  <section mat-dialog-content>
    <div>
      <div [innerHTML]="item.description"></div>
      <div *ngIf="item.file_type?.indexOf('image') > -1 && !item.thumbnails">
        <img
          alt="{{ item.file_title }}"
          src="{{'/product-extra-info-file/' + item.id + '/' + item.file_slug}}"
        />
      </div>
      <div
        *ngIf="item.file_type?.indexOf('image') == -1 && item.file_title"
        class="download"
      >
        <a
          href="/product-extra-info-file/{{ item.id }}/{{
            item.file_slug
          }}"
          target="_self"
        >
      
        <img src="/app/assets/img/download.png" />
      
          {{ removeFileExtension(item.file_title) }}
        </a>
      </div>

      <image-carousel
        *ngIf="item.thumbnails"
        [slug]="item.id"
        class="modal-carousel"
        [items]="item.thumbnails"
      ></image-carousel>
    </div>
  </section>
</div>
