<div class="item" *ngFor="let item of items">
  <div class="item-contents" (click)="navigate(item)">
    <div class="image">
      <img
        alt="{{ item.menu_title }}"
        loading="lazy"
        [src]="item.thumbnailUrl ? item.thumbnailUrl : item.imageUrl"
      />
    </div>

    <div class="description">
      <h3>{{ item.menu_title }}</h3>
    </div>
    <div class="item-price">
      <product-list-price [product]="item"></product-list-price>

      <div class="view-details">
        <p>View Details <span class="fa fa-chevron-circle-right"></span></p>
      </div>
    </div>
  </div>
</div>
