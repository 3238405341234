import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ClubsComponent} from './clubs/app.component';
import {OrdersComponent} from './orders/app.component';
import {AccountComponent} from './app.component';
import {MaterialModule} from '../../../material.module';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationsModalComponent} from './modals/notifications/app.component';
import {PasswordModalComponent} from './modals/password/app.component';
import {DetailsModalComponent} from './modals/details/app.component';
import {AddressModule} from './address/module';
import {AddressService} from '../../services/address.service';
import {TicketComponent} from './orders/tickets/app.component';
import {ProductComponent} from './orders/products/app.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AddressModule,
  ],
  declarations: [
    AccountComponent,
    ClubsComponent,
    OrdersComponent,
    NotificationsModalComponent,
    DetailsModalComponent,
    PasswordModalComponent,
    TicketComponent,
    ProductComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    AddressService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountModule {}
