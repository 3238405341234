import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './checkout-terms-modal.component.html',
  styleUrls: ['./checkout-terms-modal.component.scss'],
})
export class CheckoutTermsModalComponent {
  constructor(
    protected dialogRef: MatDialogRef<CheckoutTermsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  agreeToTerms() {
    this.dialogRef.close({hasAgreed: true});
  }
}
