import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummaryComponent } from './ordersummary.component';
import { MaterialModule } from '../../../material.module';
import { OrderService } from '../../services/order.service';
import { DataLayerService } from '../../services/data-layer-service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    OrderSummaryComponent
  ],
  providers: [DataLayerService, OrderService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrdersModule { }
