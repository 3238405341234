import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';
@Injectable()
export class ListingService {
  constructor(protected Api: ApiService) {}

  public load(listingId) {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/listing/product-id/${listingId}`
    ).then(({data}) => {
      return data;
    });
  }

  public loadRelatedContent(listingId) {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/listings/${listingId}/related-content`
    ).then(({data}) => {
      return data;
    });
  }

  public loadListingProducts(listingId) {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/products/${listingId}`
    ).then(({data}) => {
      return data;
    });
  }
}
