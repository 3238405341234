import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';
@Injectable()
export class CategoryService {
  constructor(protected Api: ApiService) {}

  public load() {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/categories`
    ).then(({data}) => {
      return data;
    });
  }

  public loadCategoryProducts(category) {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/categories/${category}`
    ).then((data) => {
      return data;
    }); 

  }
}
