import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutSummaryComponent implements OnInit {
  @Input() variant: any;
  @Input() summary: any;
  @Input() status: any;
  @Input() isDeliverable: boolean;
  @Input() activePanel: BehaviorSubject<string>;
  @Output() setPanel = new EventEmitter<string>();

  prices: {[key: string]: number} = {};

  isOptionsNotEmpty(): boolean {
    return (
      this.summary?.options && Object.keys(this.summary.options).length > 0
    );
  }

  setOptionsPanel() {
    this.setPanel.emit('options');
  }
  ngOnInit() {
    const {prices} = this.variant;

    [...Object.values(prices)].forEach((price: any) => {
      this.prices[price.slug] = price.price;
    });
  }
}
