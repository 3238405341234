import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { DataLayerService } from '../../services/data-layer-service';
import { statusTypes } from './constants';

@Component({
  selector: 'order-summary',
  templateUrl: 'ordersummary.component.html',
  styleUrl: 'ordersummary.component.scss'
})

export class OrderSummaryComponent implements OnInit {
  public loading: boolean = true;
  public orderId: string;
  public mailTo: string;
  public order: any;
  public statusType: string;
  public status: any;

  constructor(private readonly orderService: OrderService,
    private readonly dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    this.orderId = window.location.pathname.split('/')[2];
    this.statusType = window.location.pathname.split('/')[3];
    const isSuccess = this.statusType === 'success';

    try {
      const response = await this.orderService.getOrderDetails(this.orderId);
      this.order = response;
      const {uuid, item} = this.order as any || {};
      isSuccess && this.dataLayerService.logPurchase({orderId: uuid, item});
    } catch(error) {
      const {status: httpStatus, statusText} = error || {};

      const email = 'partnerchoice@johnlewis.co.uk';
      const subject = encodeURIComponent(
       `[Order Status Error] Order ID: ${this.orderId}`
      );
      const body = encodeURIComponent(`
--------------------------------------------------
ERROR DETAILS
--------------------------------------------------
Order ID:     ${this.orderId}
Order status: ${this.status}
HTTP status:  ${httpStatus} - ${statusText}
--------------------------------------------------
`);
      this.statusType = 'statusError';
      this.mailTo = `mailto:${email}?subject=${subject}&body=${body}`;
    } finally {
      const statusType =
        Object.keys(statusTypes).find(key => key === this.statusType) ||
          'statusError';

      this.status = statusTypes[statusType];
      this.loading = false;
    }
  }
}
