import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';

@Injectable()
export class OrderService {
  constructor(protected apiService: ApiService) {}

  public async createOrder(data) {
    return this.apiService.post(
      `${environment.settings.registeredAwsUrl}/checkout`,
      data
    );
  }

  public async getOrderDetails(orderId) {
    return this.apiService.get(`${environment.settings.registeredAwsUrl}/orderSummary?orderId=${orderId}`)
  }
}
