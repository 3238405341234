import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddressEditModalComponent} from '../../account/address/modals/edit/app.component';
import {BehaviorSubject, Subscription as RxSubscription} from 'rxjs';

@Component({
  selector: 'checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutAddressComponent implements OnInit, OnChanges {
  @Input() activePanel: BehaviorSubject<string>;
  @Input() deliverable: boolean;
  @Input() setAddress: Function;
  @Input() free: any;
  @Input() title: string;

  user: any;
  addressIndex: number | null = null;
  shouldDisplay: boolean;
  private readonly Subscriptions: RxSubscription[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly dialog: MatDialog
  ) {}

  ngOnChanges(): void {
    this.shouldDisplay =
      (!this.free && this.title === 'billing') ||
      (this.deliverable && this.title === 'shipping');
  }

  ngOnInit(): void {
    this.Subscriptions.push(
      this.userService.User.subscribe(data => (this.user = data.User))
    );
  }

  address() {
    const address = {...this.user.UserAddress[this.addressIndex].address};
    this.setAddress(address);
  }

  editAddressModal(index: number) {
    const isExisting = this.user.UserAddress.hasOwnProperty(index)
      ? index
      : false;
    const address = isExisting ? this.user.UserAddress[index] : {address: {}};

    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = address || {};
    config.data.userId = this.userService.User.value.User.id;
    this.dialog
      .open(AddressEditModalComponent, config)
      .afterClosed()
      .subscribe(() => {
        this.userService.loadUser();
      });
  }

  hasPhone() {
    return (
      this.addressIndex !== null &&
      !this.user.UserAddress[this.addressIndex].address.phone
    );
  }

  status() {
    return (
      this.addressIndex !== null &&
      this.user.UserAddress.hasOwnProperty(this.addressIndex) &&
      this.user.UserAddress[this.addressIndex].address.phone
    );
  }
}
