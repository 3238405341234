<div class="image-carousel">
  <section
    class="carousel is-set static"
    (swipeleft)="next(true)"
    (swiperight)="previous(true)"
    data-hm-recognizer-options="{directions: 'DIRECTION_HORIZONTAL'}"
  >
    <article *ngFor="let item of items" [ngStyle]="{order: item.carouselOrder}">
      <figure
        *ngIf="item.id == 'video'"
        class="slide video"
        (click)="showVideo(item)"
      >
        <img
          *ngIf="item.thumbnail"
          [src]="item.thumbnail"
          alt="Play video"
        />
        <span class="fa fa-youtube-play"></span>
      </figure>
      <figure
        class="slide"
        *ngIf="!item.id != 'video' && item.company_image_id"
      >
        <img
          [src]="'/CompanyImages/' + item.company_image_id + '/' + slug"
          alt="{{ item.title }}"
        />
      </figure>
    </article>
  </section>
  <div class="static">
    <button class="previous" (click)="previous(true)">
      <i class="fa fa-solid fa-chevron-left"></i>

    </button>
    <button class="next" (click)="next(true)">
      <i class="fa fa-solid fa-chevron-right"></i>
    </button>
  </div>
  <section
    *ngIf="thumbnails?.length > 3"
    class="thumbnails is-set static"
    data-hm-recognizer-options="{directions: 'DIRECTION_HORIZONTAL'}"
  >
    <article *ngFor="let item of thumbnails">
      <figure
        class="slide"
        [ngClass]="{video: item.id == 'video'}"
        (click)="onThumbnailClick(item)"
      >
        <ng-container *ngIf="item.video && item.video.thumbnail">
          <img alt="Video" src="item.video.thumbnail" />
          <span class="fa fa-youtube-play"></span>
        </ng-container>
        <img
          *ngIf="!item.id != 'video' && item.company_image_id"
          [src]="'/CompanyImages/' + item.company_image_id + '/' + slug"
          alt="{{ item.title }}"
        />
      </figure>
    </article>
  </section>
</div>
