import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ListingComponent} from './app.component';
import {ListingService} from '../../services/listing.service';
import {CompanyService} from '../../services/company.service';
import {TicketsService} from '../../services/tickets.service';
import {ProductPriceComponent} from './product-price/app.component';
import {OdneyComponent} from './odney/app.component';
import {IframeButtonComponent} from './iframe-button/app.component';
import {ProductTicketsComponent} from './product-tickets/app.component';
import {MaterialModule} from '../../../material.module';
import {CardsComponent} from '../../component/carousel/cards/app.component';
import {ProductListPriceComponent} from './product-list-price/app.component';
import {ProductProductsComponent} from './product-products/product-products.component';
import {CarouselModule} from '../../component/carousel/module';
import { SharedModule } from '../shared/module';
@NgModule({
  imports: [CommonModule, MaterialModule, CarouselModule, SharedModule],
  declarations: [
    ListingComponent,
    ProductPriceComponent,
    ProductTicketsComponent,
    OdneyComponent,
    IframeButtonComponent,
    CardsComponent,
    ProductListPriceComponent,
    ProductProductsComponent,
  ],
  providers: [ListingService, CompanyService, TicketsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListingModule {}
