import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CheckoutTermsComponent} from './checkout-terms.component';
import {CheckoutTermsModalComponent} from './checkout-terms-modal/checkout-terms-modal.component';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccountModule} from '../../account/module';

@NgModule({
  declarations: [CheckoutTermsComponent, CheckoutTermsModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, AccountModule],
  exports: [CheckoutTermsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CheckoutTermsModule {}
