import {Component} from '@angular/core';
import {HomepageService} from '../../../services/homepage.service';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'homepage',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class HomepageComponent {
  public carouselItems;
  public mainCarousels;
  public homepageProducts;
  public loading: boolean;

  constructor(
    protected homepageService: HomepageService,
    public commonService: CommonService
  ) {
    this.loading = true;
    this.homepageService.load().then(data => {
      this.carouselItems = data;
      this.mainCarousels =
        data[
          Object.keys(data).find(key => data[key].slug === 'main-carousels')
        ];
      this.homepageProducts =
        data[
          Object.keys(data).find(key => data[key].slug === 'homepage-products')
        ];
      this.loading = false;
    });
  }

  public navigate(path) {
    this.commonService.navigate(path);
  }
}
