import {Component, Input, OnInit} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {ExtraInfoContentModalComponent} from './extra-info-content/app.component';
@Component({
  selector: 'extra-info',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ExtraInfoComponent implements OnInit {
  @Input() productInfo;
  @Input() type;
  public sortedProduct;
  public displayAccordion;

  constructor(
    protected dialogRef: MatDialogRef<ExtraInfoComponent>,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.sortedProduct = this.productInfo
      .filter(item => item.type && item?.type.indexOf(this.type) !== -1)
      .sort((a, b) =>
        a['order'] > b['order'] ? 1 : a['order'] === b['order'] ? 0 : -1
      );
  }

  setAccordion(accordionTitle) {
    this.displayAccordion =
      this.displayAccordion !== accordionTitle ? accordionTitle : null;
  }

  removeFileExtension(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  display(info) {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = info;
    this.dialog.open(ExtraInfoContentModalComponent, config);
  }
}
