import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {tsAndCs} from '../../../../environment/constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'checkout-products',
  templateUrl: './checkout-products.component.html',
  styleUrls: ['./checkout-products.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutProductsComponent implements OnInit {
  @Input() activePanel: BehaviorSubject<string>;
  @Input() variant: any = {};
  @Input() product: any = {};
  @Input() setOptions: any = {};
  @Input() close: any = {};
  @Input() status: any = {};

  public pickOptionsForm: FormGroup;
  public items: any = {
    adultPartner: {
      label: null,
      amount: null,
    },
    delivery: {
      label: null,
      amount: null,
    },
  };
  public total = {
    cost: 0,
    items: 0,
  };
  public showHurry: boolean = false;
  public tsAndCs = tsAndCs.product;
  terms: boolean = false;

  constructor() {
    this.pickOptionsForm = new FormGroup({
      amount: new FormControl(this.items.adultPartner.amount),
      deliveryAmount: new FormControl(this.items.delivery.amount),
    });
  }

  ngOnInit(): void {
    if (
      this.product.hasOwnProperty('terms_and_conditions') &&
      !!this.product.terms_and_conditions
    ) {
      this.tsAndCs = this.product.terms_and_conditions;
    }

    this.pickOptionsForm.get('amount').valueChanges.subscribe(value => {
      this.items.adultPartner.amount = value;
      this.updateTotal();
    });

    this.pickOptionsForm.get('deliveryAmount').valueChanges.subscribe(value => {
      this.items.delivery.amount = value ? 1 : 0;
      this.updateTotal();
    });

    const {ticketsPerPartner, stockQuantity} = this.variant;
    this.showHurry =
      stockQuantity !== null &&
      ((ticketsPerPartner === null && stockQuantity < 5) ||
        stockQuantity < ticketsPerPartner);

    this.items = {
      adultPartner: {
        label: this.variant.title,
        amount: null,
      },
      delivery: {
        label: 'delivery',
        amount: null,
      },
    };
  }

  addOptions = () => {
    const {adultPartner, delivery} = this.items;

    const options = {
      adultPartner: {...adultPartner},
      delivery: {...delivery},
    };

    options.delivery.amount === true && (options.delivery.amount = 1);

    this.setOptions(options);
  };

  updateTotal() {
    this.total.cost = 0;
    this.total.items = 0;

    const {amount: deliveryAmount} = this.items.delivery;
    const {amount: productAmount} = this.items.adultPartner;

    const {deliveryPrice, price} = this.variant;

    const priceOfDelivery = (deliveryAmount ? deliveryPrice : 0) * 1;
    const productPrice = price * productAmount;

    this.total.cost = priceOfDelivery + productPrice;
    this.total.items = productAmount;
  }

  disabled() {
    const {ticketsPerPartner, stockQuantity} = this.variant;

    return (
      this.pickOptionsForm.invalid ||
      this.total.items === 0 ||
      this.items > stockQuantity ||
      (ticketsPerPartner && this.total.items > ticketsPerPartner) ||
      !this.terms
    );
  }
}
