<div class="page-loading" *ngIf="loading">
<span>
  <span></span>
</span>
</div>
<div>
  <div>
    <h1>
      <span class="fa fa-search"></span> Search Results
      <small>"{{ searchTerm }}"</small>
    </h1>
  </div>
  <div class="small-12 columns" *ngIf="listings && listings.length < 1 && !loading">
    <p>No results found for: "{{ searchTerm }}"</p>
  </div>
  <div *ngIf="listings">
    <product-list [items]="listings"></product-list>
  </div>
</div>
