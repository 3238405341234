import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'iframe-button',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class IframeButtonComponent implements OnInit {
  public budgetClass;
  public iframeUrl;
  public constructor(
    protected Dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) protected config,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    const {url} = this.config.url;
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.budgetClass =
      url ===
      'https://partner-tools.moneyadviceservice.org.uk/en/tools/budget-planner';
  }
}
