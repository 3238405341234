import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../../../services/common.service';
// import {UserService} from '../../../services/user.service';

@Component({
  selector: 'ticket-component',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class TicketComponent implements OnInit {
  @Input() public ticket;
  public dateString;
  public ticketString;
  constructor(
    // protected userService: UserService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    console.log(this.ticket);
    this.dateString = this.ticket.Product.ProductVariant.start_date;
    this.dateString += this.ticket.Product.ProductVariant.end_date
      ? `-${this.ticket.Product.ProductVariant.end_date}`
      : '';

    this.ticketString = `${this.ticket.total_tickets} ticket${
      (this.ticket.total_tickets > 1 && 's') || ''
    }`;
  }

  formatTicketPrice(ticketPrice) {
    return `£${(ticketPrice).toFixed(2)}`;
  }
  
  public navigate(path) {
    this.commonService.navigate(path);
  }
}
