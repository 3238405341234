import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../../../services/common.service';
// import {UserService} from '../../../services/user.service';

@Component({
  selector: 'product-component',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ProductComponent implements OnInit{
  @Input() public product;
  public dateString;
  public ticketString;
  constructor(
    public commonService: CommonService
  ) {

  }

  ngOnInit(): void {
   
  }

  formatTicketPrice(ticketPrice) {
    return `£${(Number(ticketPrice) / 100).toFixed(2)}`;
  }

  public navigate(path) {
    this.commonService.navigate(path);
  }
}
