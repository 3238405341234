import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CheckoutComponent} from './checkout.component';
import {CheckoutSummaryComponent} from './checkout-summary/checkout-summary.component';
import {CheckoutOptionComponent} from './checkout-option/checkout-option.component';
import {CheckoutProductsComponent} from './checkout-products/checkout-products.component';
import {CheckoutCardComponent} from './checkout-card/checkout-card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../material.module';
import {MatDialogRef} from '@angular/material/dialog';
import {OrderService} from '../../services/order.service';
import {CheckoutTermsModule} from './checkout-terms/module';
import {CheckoutAddressComponent} from './checkout-address/checkout-address.component';
import { DataLayerService } from '../../services/data-layer-service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CheckoutTermsModule,
  ],
  declarations: [
    CheckoutComponent,
    CheckoutSummaryComponent,
    CheckoutOptionComponent,
    CheckoutProductsComponent,
    CheckoutCardComponent,
    CheckoutAddressComponent,
  ],

  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    OrderService,
    DataLayerService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CheckoutModule {}
