<div class="item-contents" *ngIf="ticket">
    <div
      class="image"
      style="background-image:url('/ProductImages/{{ ticket.Product.id }}/thumbnail/{{ ticket.Product.slug }}image.jpg')"
    ></div>
  
    <div class="description">
      <h3>{{ ticket.Product.title }}</h3>
  
      <p>{{ dateString }}</p>
      <p data-jlp-feature-inactive="ticketPdf">{{ ticketString }}</p>
      <ng-template
        class="ticket-pdf-component"
        data-jlp-feature-active="ticketPdf"
      >
        <p data-ng-if="!ticket.ticketPdf">{{ ticketString }}</p>
        <ng-container
          *ngFor="let pdf of ticket.ticketPdf"
          class="ticket-pdf-component"
        >
          <a ng-href="{{pdf}}" target="_blank" class="button ticket-button">
            <span class="fa fa-file-pdf-o"></span> Download tickets
          </a>
        </ng-container>
      </ng-template>
      <p>
        {{ ticket.total_price == '0' && 'Free' || formatTicketPrice(ticket?.total_price) }}
      </p>
      <p>{{ ticket.Product.ProductVariant.CompanyLocation[0].title }}</p>
    </div>
    <div class="item-price">
      <p>
        <span class="full"> {{ ticket.state | titlecase }} </span>
      </p>
    </div>
  </div>
  