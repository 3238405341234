<div class="product-price clearfix price-1" *ngIf="price.price">
  <div class="price full-width">
    <p>
      <span *ngIf="price.title">{{ price.title }}</span>
      {{ price.price }}
    </p>
  </div>

  <p *ngIf="listing.price_terms" class="definition">
    {{ listing.price_terms }}
  </p>
</div>
