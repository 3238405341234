import angular from 'angular';

import PartnerChoiceCoreCheckout from './checkout/checkout-module';
import FeatureModule from './feature/feature-module';
import HomePageComponent from './home-page/home-page-component';
import IframeButtonComponent from './iframe-overlay/iframe-button-component';
import IframeOverlayModalController from './iframe-overlay/iframe-overlay-modal-controller';
import MessageComponent from './message/message-component';
import NotificationModule from './notification/notification-module';
import PartnerChoiceCoreOrders from './order/order-module';
import OrderSummaryComponent from './order-summary/order-summary-component';
import ProductComponent from './product/product-component';
import ProductListComponent from './product-list/product-list-component';
import ProductListPriceComponent from './product-list-price/product-list-price-component';
import ProductOdneyComponent from './product-odney/product-odney-component';
import ProductPriceComponent from './product-price/product-price-component';
import ProductProductComponent from './product-product/product-product-component';
import ProductTicketComponent from './product-ticket/product-ticket-component';
import ProfileComponent from './profile/profile-component';
import ProfileAddressComponent from './profile-address/profile-address-component';
import SearchPanelComponent from './search-panel/search-panel-component';
import SearchResultComponent from './search-result/search-result-component';

export default angular
  .module('PartnerChoice.user.component', [
    FeatureModule.name,
    NotificationModule.name,
    PartnerChoiceCoreOrders.name,
    PartnerChoiceCoreCheckout.name,
  ])

  .component('jlpHomePageComponent', HomePageComponent)
  .component('messages', MessageComponent)
  .component('jlpOrderSummaryComponent', OrderSummaryComponent)
  .component('jlpIframeButtonComponent', IframeButtonComponent)
  .controller('IframeOverlayModalController', IframeOverlayModalController)
  .component('productComponent', ProductComponent)
  .component('jlpProductListComponent', ProductListComponent)
  .component('productListPrice', ProductListPriceComponent)
  .component('productOdney', ProductOdneyComponent)
  .component('productPrice', ProductPriceComponent)
  .component('productProducts', ProductProductComponent)
  .component('jlpProductTickets', ProductTicketComponent)
  .component('profileComponent', ProfileComponent)
  .component('jlpProfileAddressComponent', ProfileAddressComponent)
  .component('productSearchResultsComponent', SearchResultComponent)
  .component('searchPanelComponent', SearchPanelComponent);
