export default function RoutesProvider() {
  this.$get = function () {
    return {
      Page: {
        abstract: true,
        views: {
          page: {
            templateUrl: 'app/view/pages/page.html',
            controller: 'UserPageController',
            controllerAs: 'page',
          },
          'navigationButtons@Page': {
            templateUrl: 'app/view/pages/elements/navigation-buttons.html',
          },
        },
      },
      // 'Page.Home': {
      //   title: 'Home',
      //   url: '/',
      //   views: {
      //     main: 'jlpHomePageComponent',
      //   },
      // },
      Search: {
        title: 'Search',
        url: '/search/:search',
        views: {
          main: 'productSearchResultsComponent',
        },
        params: {
          search: null,
        },
      },
      Company: {
        // Title set in component
        // title: 'Company Details',
        url: '/company/:slug',
        views: {
          main: 'companyComponent',
        },
        params: {
          slug: {
            value: null,
          },
        },
      },
      Listing: {
        // Title set in component
        // title: 'Product Details',
        url: '/listing/:id/:slug',
        views: {
          main: 'productComponent',
        },
        params: {
          id: {
            value: null,
          },
          slug: {
            value: null,
          },
          tag: {
            value: 'Listings',
            squash: true,
          },
        },
      },
      Ballot: {
        // Title set in component
        // title: 'Product Details',
        url: '/ballot/:id/:slug',
        views: {
          main: 'productComponent',
        },
        params: {
          id: {
            value: null,
          },
          slug: {
            value: null,
          },
          tag: {
            value: 'Ballots',
            squash: true,
          },
        },
      },
      Checkout: {
        title: 'Order Confirmation',
        url: '/Checkout',
        views: {
          main: {
            templateUrl: '/view/Checkout/checkout',
            controller: 'CheckoutController',
            controllerAs: 'checkout',
          },
        },
      },
      'Checkout.confirmed': {
        title: 'Order Confirmed',
        url: '/orderConfirmation',
        views: {
          'main@Page': {
            templateUrl: 'app/view/checkout/order-confirmation.html',
            controller: 'CheckoutCompleteController',
            controllerAs: 'checkout',
          },
        },
        params: {
          order: {
            value: {},
            squash: true,
          },
          message: {
            value: '',
            squash: true,
          },
        },
      },
      'Checkout.refused': {
        title: 'Order refused',
        url: '/orderRefused',
        views: {
          'main@Page': {
            templateUrl: 'app/view/checkout/order-refused.html',
            controller: 'CheckoutCompleteController',
            controllerAs: 'checkout',
          },
        },
        params: {
          order: {
            value: {},
            squash: true,
          },
          message: {
            value: '',
            squash: true,
          },
        },
      },
      'Order.summary': {
        title: 'Order Summary',
        url: '/order/:orderId/:status',
        views: {
          main: 'jlpOrderSummaryComponent',
        },
        params: {
          orderId: null,
          status: null,
        },
      },
      User: {
        title: 'My Profile',
        url: '/Users/my-profile',
        views: {
          main: 'profileComponent',
        },
      },
      UserAddress: {
        title: 'My Addresses',
        url: '/Users/address',
        views: {
          main: 'jlpProfileAddressComponent',
        },
      },
      UserOrders: {
        title: 'My Orders',
        url: '/Users/my-orders',
        views: {
          main: 'ordersListComponent',
        },
      },
      // UserClubs: {
      //   title: 'My Clubs',
      //   url: '/Users/my-clubs',
      //   views: {
      //     main: {
      //       templateUrl: 'app/view/users/my-clubs.html',
      //       controller: 'UserClubsController',
      //       controllerAs: 'List',
      //     },
      //   },
      // },
    };
  };
}