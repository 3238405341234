import {Component, ElementRef, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'cards-carousel',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class CardsComponent implements OnInit, OnChanges {
  @Input() items = [];
  public loading = false;

  public sortedItems;
  public timer = null;
  public rootElem;
  public activeSlide;
  public delay = 250;
  public next = this.nextSlide;
  public previous = this.previousSlide;
  public step = 4750;
  el: ElementRef;

  constructor(private router: Router, public commonService: CommonService, el: ElementRef) {
    this.el = el;
  }

  ngOnInit() {
  
    this.rootElem = $($(this.el.nativeElement).children('.cards-carousel')[0]);
  }

  public ngOnChanges() {
    this.sortedItems = this.items.sort((a, b) =>
      a['carousel_order'] > b['carousel_order']
        ? 1
        : a['carousel_order'] === b['carousel_order']
        ? 0
        : -1
    );
    const isCarousel = this.sortedItems.length > 1;

    Promise.resolve()
      .then(() => (this.loading = true))
      .then(() => this.setVideo())
      .then(() => {
        if (!isCarousel) {
          return;
        }
        this.setTheOrder();

        this.rootElem.children().removeClass('static');
        !!this.timer && clearTimeout(this.timer);
      })
      .finally(() => (this.loading = false));
  }


  public nextSlide(manual) {
    !!this.timer && clearTimeout(this.timer);

    this.moveForward();

    !manual && (this.timer = setTimeout(this.nextSlide, this.step));
  }

  public previousSlide(manual) {
    !!this.timer && clearTimeout(this.timer);

    this.moveBackward();

    !manual && (this.timer = setTimeout(this.nextSlide, this.step));
  }


  public moveForward() {
    const $el = this.rootElem.children('.carousel');
    $el.removeClass('is-set').removeClass('is-reversing');
    
    this.sortedItems.forEach(item => {
      item.carouselOrder--;
      
      item.carouselOrder < 0 && (item.carouselOrder += this.sortedItems.length);
      
      item.carouselOrder === 2 && (this.activeSlide = item);
    });

    setTimeout(() => {
      $el.addClass('is-set');
    });
  }

  public moveBackward() {
    this.rootElem
      .children('.carousel')
      .removeClass('is-set')
      .addClass('is-reversing');

    this.sortedItems.forEach(item => {
      item.carouselOrder++;

      item.carouselOrder >= this.sortedItems.length &&
        (item.carouselOrder -= this.sortedItems.length);

      item.carouselOrder === 2 && (this.activeSlide = item);
    });

    setTimeout(() => {
      this.rootElem.children('.carousel').addClass('is-set');
    }, this.delay);
  }

  public setTheOrder() {
    let index = 0;

    if (!this.rootElem.hasClass('no-duplicate')) {
      this.sortedItems.length > 1 &&
  
        (this.items = this.sortedItems.concat(this.sortedItems.map(item => ({...item}))));
        index = 2;
    }

    this.sortedItems.forEach(item => {
      item.carouselOrder = index;

      index++;

      index >= this.sortedItems.length && (index -= this.sortedItems.length);
    });
  }


  public setVideo() {
    const item = this.items ? this.sortedItems.find(({video}) => video) : null;
    if (!item) {
      return;
    }

    try {
      const {video: url} = item;
      // const {id, service} = getVideoId(url) || {};

      // switch (service) {
      //   case 'youtube':
      //     return this.setYouTubeVideo(item, id);
      //   case 'vimeo':
      //     return this.setVimeoVideo(item);
      //   default:
      //     this.removeItem(item);
      //     return Promise.resolve();
      // }
    } catch (error) {
      return Promise.resolve();
    }
  }
  public navigate(path) {
    const url =
      path.state === 'listing'
        ? `/listing/${path.id}/${path.slug}`
        : `/company/${path.companySlug}`;
    this.commonService.navigate(url);
  }
}
