import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../../../services/common.service';

@Component({
  selector: 'product-list',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class ProductListComponent implements OnInit {
  @Input() items;

  constructor(public commonService: CommonService) {}

  ngOnInit() {

  }

  public navigate(path) {
    const url =
      path.state === 'listing'
        ? `/listing/${path.id}/${path.slug}`
        : `/company/${path.companySlug}`;
    this.commonService.navigate(url);
  }
}
