<section class="checkout-modal">
    <div class="page-loading" *ngIf="loading">
        <span>
            <span></span>
        </span>
    </div>
    <article *ngIf="!loading">
        <button class="close" (click)="close()" style="margin-top: -15px;">X</button>
        <checkout-summary [variant]="variant" [summary]="summary" [status]="status" [isDeliverable]="isDeliverable"
            (setPanel)="setPanel($event)" [activePanel]="activePanel"></checkout-summary>
        <section class="purchase">
            <h1>{{ product.title }}</h1>
            <checkout-option *ngIf="product.features.ticket" [activePanel]="activePanel" [variant]="variant"
                [product]="product" [setOptions]="setOptions" [close]="close" [status]="status"></checkout-option>
            <checkout-products *ngIf="product.features.product" [activePanel]="activePanel" [variant]="variant"
                [product]="product" [setOptions]="setOptions" [close]="close" [status]="status"></checkout-products>
            <checkout-address [free]="isFree" [activePanel]="activePanel" [deliverable]="isDeliverable" title="billing"
                [setAddress]="setBillingAddress"></checkout-address>
            <checkout-address [free]="isFree" [activePanel]="activePanel" [deliverable]="isDeliverable" title="shipping"
                [setAddress]="setShippingAddress"></checkout-address>
            <checkout-card *ngIf="!isFree" [activePanel]="activePanel"></checkout-card>
            <section class="free-purchase" *ngIf="activePanel.getValue() == 'free-purchase'">
                <button class="button accordion-toggle" (click)="freePurchase()">
                    Purchase
                </button>
            </section>
        </section>
    </article>
</section>