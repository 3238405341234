<section class="product-tickets">
  <section class="filter" *ngIf="showFilter">
    <h1>Choose Location:</h1>
    <button (click)="setFilter()" [ngClass]="{disabled: !filter}">All</button>
    <button *ngFor="let location of tickets.locations" (click)="setFilter(location.id)"
      [ngClass]="{disabled: location.id == filter}">
      {{ location.title }}
    </button>
  </section>
  <section class="months">
    <section class="month" *ngFor="let month of tickets.dates">
      <header (click)="setMonth(month.dateString)" (keydown)="setMonth(month.dateString)" class="dropdown button secondary tiny">
        <h1>
          {{ month.dateString + '-01' | date : 'MMM yyyy' }}<br />
          <small *ngFor="let day of month.days | keyvalue; let last = last" [ngClass]="{'sold-out': !day.value}">
            {{ day.key | date : 'd MMM' }}{{ (!last && ', ') || '' }}
          </small>
        </h1>
      </header>
      <section class="days" *ngIf="displayMonth == month.dateString">
        <section class="day" *ngFor="let ticket of month.tickets"
          [hidden]="filter && ticket.CompanyLocation.id !== filter"
          [ngClass]="{disabled: ticket.purchased || !ticket.stock_quantity}">
          <h1>
            {{ ticket.day | date : 'd MMM' }}
            {{ (ticket.start_time && ticket.start_time.name) || '' }}<br />
            <small>{{ ticket.displayDate }}</small>
          </h1>
          <p>{{ ticket.displayLocation }}</p>
          <small *ngIf="isBallot">
            You have until
            {{
            ticket.end_apply_date.date | dateToIso | date : 'EEEE MMMM d yyyy'
            }}
            to apply.
          </small>
          <h2 class="partner-price">

            {{ formatPrice(ticket.prices[0].price) }}<br />
            <br />
            <small>{{ ticket.prices[0].label }}</small>
          </h2>
          <button *ngIf="
              !ticket.staged_release && (!ticket.purchased || ticket.anarchy)
            " [disabled]="!ticket.stock_quantity" (click)="pickOptions(ticket)">
            {{
            (isBallot && 'Apply Now') ||
            (!!ticket.stock_quantity &&
            'Buy Now') ||
            'Sold Out'
            }}
          </button>
          <button class="ticket-button" *ngIf="
              ticket.staged_release && (!ticket.purchased || ticket.anarchy)
            " (click)="pickOptions(ticket)" [disabled]="!ticket.stock_quantity">
            {{ checkStagedRelease(ticket) }}
          </button>
          <button disabled *ngIf="ticket.purchased && !ticket.processing && !ticket.anarchy">
            {{ (isBallot && 'Applied') || 'Purchased' }} on :
            {{ ticket.purchased * 1000 | date }}
          </button>
          <button disabled *ngIf="ticket.processing && !ticket.anarchy">
            Processing...
          </button>
        </section>
      </section>
    </section>
  </section>
  <button *ngIf="tickets && !tickets.available" disabled>Sold out!</button>
</section>