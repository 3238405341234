import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {numericValidator} from '../../../validators/numeric-validator';
import {passwordMatchValidator} from '../../../validators/password-match';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {
  public personalForm: FormGroup;
  public locationForm: FormGroup;
  public preferencesForm: FormGroup;
  public accountForm: FormGroup;

  public personalOpenState: boolean = true;
  public locationOpenState: boolean = false;
  public preferencesOpenState: boolean = false;
  public registerOpenState: boolean = false;
  public accountOpenState: boolean = false;

  public retired: boolean = false;

  public loading: boolean = false;

  constructor(
    public fb: FormBuilder,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.personalForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      employeeNumber: [
        '',
        [Validators.required, Validators.minLength(8), numericValidator()],
      ],
    });

    this.locationForm = this.fb.group({
      branchNumber: [{value: '', disabled: this.retired}],
      retiredPartner: [false],
    });

    this.preferencesForm = this.fb.group({
      receiveMarketingEmails: [false],
    });

    this.accountForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]],
      },
      {validators: passwordMatchValidator}
    );
  }

  setPanelState(panel: string) {
    this.personalOpenState = panel === 'personal';
    this.locationOpenState = panel === 'location';
    this.preferencesOpenState = panel === 'preferences';
    this.registerOpenState = panel === 'register';
    this.accountOpenState = panel === 'account';
  }

  updateRetired($event: any) {
    this.retired = $event.target.checked;
    if (this.retired) {
      this.locationForm.get('branchNumber').disable();
    } else {
      this.locationForm.get('branchNumber').enable();
    }
  }

  async register() {
    try {
      if (
        this.personalForm.invalid ||
        this.locationForm.invalid ||
        this.preferencesForm.invalid ||
        this.accountForm.invalid
      ) {
        return;
      }
      this.loading = true;
      const features =
        this.preferencesForm.getRawValue().receive_marketing_emails === true
          ? {receive_marketing_emails: true}
          : {};

      const user: any = {
        email: this.accountForm.getRawValue().email,
        employee_number: this.personalForm.getRawValue().employeeNumber,
        features: features,
        first_name: this.personalForm.getRawValue().firstName,
        last_name: this.personalForm.getRawValue().lastName,
        password: this.accountForm.getRawValue().password,
        passwordConfirm: this.accountForm.getRawValue().passwordConfirm,
      };

      if (this.retired) {
        user.retired_partner = true;
      } else {
        user.branch_number = this.locationForm.getRawValue().branchNumber;
      }
      const response = await this.userService.registerUser({User: user});
      if (response.body.status === true) {
        this.router.navigate(['/Login']);
      }
    } catch (error) {
      this.loading = false;
    }
  }
}
