import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SearchComponenet} from './components/app.component';
import {MaterialModule} from '../../../material.module';
import { CategoryModule } from '../category/module';
import { SharedModule } from '../shared/module';

@NgModule({
  imports: [CommonModule, MaterialModule, CategoryModule, SharedModule],
  declarations: [SearchComponenet],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchModule {}