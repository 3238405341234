import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ImageCarouselComponent} from '../../component/carousel/image-carousel/app.component';
import {MaterialModule} from '../../../material.module';
@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    ImageCarouselComponent,
  ],
  exports: [ImageCarouselComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselModule {}
