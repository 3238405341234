import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {zdApiUrl} from '../../../../environment/constants';
import {ApiService} from '../../../services/api.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {CheckoutTermsModalComponent} from './checkout-terms-modal/checkout-terms-modal.component';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'checkout-terms',
  templateUrl: './checkout-terms.component.html',
  styleUrls: ['./checkout-terms.component.scss'],
})
export class CheckoutTermsComponent implements OnInit {
  @Input() termsUrl: string;
  @Output() onAgree = new EventEmitter<void>();
  @Output() onDisagree = new EventEmitter<void>();
  @Input() close: any;

  user: any;
  public loading: boolean = true;
  public hasReadTerms = false;
  public hasAgreedTerms = false;
  public termsId;
  public terms = null;

  constructor(
    protected userService: UserService,
    private readonly apiService: ApiService,
    protected readonly dialogRef: MatDialogRef<CheckoutTermsComponent>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userService.User.subscribe(data => {
      if (data?.User) {
        this.user = data.User;
        this.loadTerms();
      }
    });
  }

  onAgreeChange() {
    if (this.hasAgreedTerms) {
      this.onAgree.emit();
    } else {
      this.onDisagree.emit();
    }
  }

  loadTerms() {
    const rx = /(\d+)(?:-([\w-]+))?$/g;
    [, this.termsId] = rx.exec(this.termsUrl) || [];

    const endpoint = `${zdApiUrl}/help_center/articles/${this.termsId}`;

    this.loading = true;
    this.apiService.get(endpoint).then(data => {
      const {article: terms} = data;
      this.terms = terms;
      const {edited_at} = terms;
      this.hasReadTerms =
        localStorage.getItem(`${this.user.id}-ts-and-cs-${this.termsId}`) ===
        edited_at;
      this.loading = false;
    });
  }

  showTerms() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = {
      terms: this.terms,
    };
    this.dialog
      .open(CheckoutTermsModalComponent, config)
      .afterClosed()
      .subscribe(hasAgreed => {
        if (hasAgreed) {
          this.hasAgreedTerms = hasAgreed;
          this.hasReadTerms = hasAgreed;

          const {edited_at} = this.terms;

          localStorage.setItem(
            `${this.user.id}-ts-and-cs-${this.termsId}`,
            edited_at
          );
          this.onAgreeChange();
        }
      });
  }
}
