import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';
import environment from '../../environment';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  constructor(protected Api: ApiService, protected authService: AuthService) {
    authService.isAuthenticated.subscribe(isLoggedIn => isLoggedIn ? this.loadUser() : this.User.next(null))
  }
  public User: BehaviorSubject<any> = new BehaviorSubject([]);

  public async loadUser() {
    const result = await this.Api.get(
      `${environment.settings.registeredAwsUrl}/user`
    );
    this.User.next(result.data);
  }

  public async getUserClubs() {
    return await this.Api.get(`${environment.settings.registeredAwsUrl}/clubs`);
  }

  public async updateUserPreferences(userId, data) {
    return this.Api.post(
      `${environment.settings.registeredAwsUrl}/Users/${userId}/preferences`,
      data
    );
  }

  public async updateUser(data) {
    return this.Api.put(
      `${environment.settings.registeredAwsUrl}/Users/${data.id}`,
      data
    );
  }

  public async getUserByLoginHash(loginHash): Promise<any> {
    return await this.Api.unauthenticatedGet(
      `${environment.settings.unregisteredAwsUrl}/reset-password/hash/${loginHash}`
    );
  }

  public async resetPasswordEmail(email, employeeNumber): Promise<any> {
    return await this.Api.unauthenticatedPost(
      'reset-password-email',
      {email: email, employeeNumber: employeeNumber},
      `${environment.settings.unregisteredAwsUrl}`
    );
  }

  public async resetPassword(loginHash, userId, body): Promise<any> {
    return await this.Api.unauthenticatedPut(
      `reset-password/${userId}/${loginHash}`,
      body,
      `${environment.settings.unregisteredAwsUrl}`
    );
  }

  public async registerUser(body): Promise<any> {
    return await this.Api.unauthenticatedPost(
      'register',
      body,
      `${environment.settings.unregisteredAwsUrl}`
    );
  }
  
  public async getUserOrders() {
    return await this.Api.get(`${environment.settings.registeredAwsUrl}/purchases`);
  }
}
