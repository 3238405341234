import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './angular/modules/homepage/components/app.component';
import {LoginComponent} from './angular/modules/login/login.component';
import {ForgottenPasswordComponent} from './angular/modules/login/forgotten-password/forgotten-password.component';
import {ResetPasswordComponent} from './angular/modules/login/reset-password/reset-password.component';
import {RegisterComponent} from './angular/modules/login/register/register.component';
import {AuthGuard} from './angular/guards/auth.guard';
import {LoggedInGuard} from './angular/guards/logged-in.guard';
import {OldLeisureComponent} from './angular/modules/old-leisure/components/app.component';
import {ListingComponent} from './angular/modules/listing/app.component';
import {ClubsComponent} from './angular/modules/account/clubs/app.component';
import {AccountComponent} from './angular/modules/account/app.component';
import {AddressComponent} from './angular/modules/account/address/app.component';
import {CategoryComponent} from './angular/modules/category/app.component';
import {CompanyComponent} from './angular/modules/company/app.component';
import {OrdersComponent} from './angular/modules/account/orders/app.component';
import {SearchComponenet} from './angular/modules/search/components/app.component';
import {OrderSummaryComponent} from './angular/modules/orders/ordersummary.component';

const routes: Routes = [
  {path: 'Login', component: LoginComponent, canActivate: [LoggedInGuard]},
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'Forgotten/reset/:loginHash',
    component: ResetPasswordComponent,
    canActivate: [LoggedInGuard],
  },
  {path: '', component: HomepageComponent, canActivate: [AuthGuard]},
  {
    path: 'listing/:listingId/:slug',
    component: ListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'company/:slug',
    component: CompanyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Users/my-profile',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {path: 'Users/my-clubs', component: ClubsComponent, canActivate: [AuthGuard]},
  {
    path: 'Users/address',
    component: AddressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Users/my-orders',
    component: OrdersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'category/:slug',
    component: CategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'search/:searchTerm',
    component: SearchComponenet,
    canActivate: [AuthGuard],
  },
  {path: 'order/:orderId/:status', component: OrderSummaryComponent, canActivate: [AuthGuard]},

  {path: 'ballot/:id/:slug', component: OldLeisureComponent},
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
