
<div class="account-profile">
  <section class="profile-editable get-started">
    <a (click)="navigate('listing/7199/welcome-to-partnerchoice')">
      <div class="details">
        <h3>Getting Started</h3>
        <p>Explore how to get the most out of partnerchoice.co.uk</p>
      </div>
      <div class="action">
        <span class="fa fa-chevron-right"></span>
      </div>
    </a>
  </section>

  <section class="profile-editable">
    <div (click)="editDetails()">
      <div class="details">
        <h3><span class="fa fa-address-card-o"></span> My Details</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </div>

    <div (click)="navigate('/Users/address')">
      <div class="details">
        <h3><span class="fa fa-home"></span> Address Book</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </div>

    <div (click)="editNotifications()">
      <div class="details">
        <h3><span class="fa fa-envelope"></span> Email Notifications</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </div>

    <div (click)="editPassword()">
      <div class="details">
        <h3><span class="fa fa-key"></span> Change Password</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </div>

    <a (click)="navigate('/Users/my-orders')" title="">
      <div class="details">
        <h3><span class="fa fa-list-alt"></span> Purchases</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </a>

    <a (click)="navigate('/Users/my-clubs')" title="">
      <div class="details">
        <h3><span class="fa fa-users"></span> Partnership Clubs</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </a>

    <a
      target="_blank"
      href="https://issuu.com/partnerchoice/docs/discount_leaflet_march_2024_1_?fr=sODdkODcyMDU5NjM"
      title=""
    >
      <div class="details">
        <h3>
          <span class="fa fa-percent"></span> Partnership Discount Information
        </h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </a>

    <a
      target="_blank"
      href="https://jlpleisurebenefits.zendesk.com/home"
      title=""
    >
      <div class="details">
        <h3><span class="fa fa-question-circle"></span> Help</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </a>

    <a (click)="navigate('/Logout')" title="">
      <div class="details">
        <h3><span class="fa fa-sign-out"></span> Sign Out</h3>
      </div>
      <div class="action"><span class="fa fa-chevron-right"></span></div>
    </a>
  </section>
</div>
