import { Location } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class DataLayerService {
  private readonly dataLayer: any;

  constructor(private readonly location: Location) {
    this.dataLayer = (window as any).dataLayer || [];
  }

  public push(stuff) {
    try {
      return this.dataLayer.push(stuff);
    } catch (error) {
      console.error(error);
    }
  }

  public logPageView(title) {
    try {
      this.logEvent('page_view', {
        virtualPageTitle: title,
        virtualPageUrl: this.location.path(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  public logPurchase({orderId, item}) {
    try {
      const {product_id, product_variant_id, title, total = 0, options = {}} =
        item || {};
      const items = Object.keys(options)
        .filter(slug => options[slug].price)
        .map(slug => {
          const {label, price = 0, qty: quantity} = options[slug];

          return {
            item_id: `${product_id}-${product_variant_id}`,
            item_name: `${title}: ${label}`,
            quantity,
            price: price ? (price / 100).toFixed(2) : 0,
          };
        });
      const data = {
        transaction_id: orderId,
        items,
        currency: 'GBP',
        value: total ? (total / 100).toFixed(2) : 0,
      };

      this.logEvent('purchase', data);
    } catch (error) {
      console.error(error);
    }
  }

  public logLogin() {
    try {
      this.logEvent('login', {loginMethod: 'email'});
    } catch (error) {
      console.error(error);
    }
  }

  public logSearch(searchTerm) {
    try {
      this.logEvent('search', {searchTerm});
    } catch (error) {
      console.error(error);
    }
  }

  public logEvent(event, data) {
    try {
      this.push({event, ...data});
    } catch (error) {
      console.error(error);
    }
  }
}
