import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../services/category-service';

@Component({
  selector: 'app-category',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class CategoryComponent implements OnInit {
  public loading: boolean = false;
  public category;
  constructor(protected categoryService: CategoryService) {}

  ngOnInit() {
    this.loading = true;

    const category = window.location.pathname.split('/').pop();
    this.categoryService.loadCategoryProducts(category).then(data => {
      this.category = data;
      this.loading = false;
    });
  }
}
