import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import environment from '../../../../environment';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'checkout-card',
  templateUrl: './checkout-card.component.html',
  styleUrls: ['./checkout-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutCardComponent implements OnInit {
  @Input() activePanel: BehaviorSubject<string>;

  public showCards: boolean = false;

  ngOnInit(): void {
    this.showCards = environment.settings.debug;
  }
}
