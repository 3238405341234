import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'odney',
  templateUrl: 'app.component.html',
})
export class OdneyComponent implements OnInit {
  @Input() product;
  ngOnInit(): void {
  }
}
