import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {CompanyService} from '../../services/company.service';
import {Subscription as RxSubscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import environment from '../../../environment';

@Component({
  selector: 'company-component',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class CompanyComponent implements OnInit {
  public company;
  private Subscriptions: RxSubscription[] = [];
  public user;
  public loading: boolean;

  constructor(
    protected companyService: CompanyService,
    public commonService: CommonService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.Subscriptions.push(
      this.userService.User.subscribe(data => (this.user = data.User))
    );
    this.getData();
  }

  getData() {
    const companySlug = window.location.pathname.split('/')[2];
    this.companyService.load(companySlug).then(company => {
      this.company = company;
      this.loading = false;
    });
  }

  public navigate(path) {
    window.location.href = path;
  }

  showProducts() {
    return this.company.filter !== 'club' || this.isPartOfTheGroup();
  }

  leaveClub() {
    this.companyService
      .clubStatus(this.company.id, 'leave')
      .then(() => window.location.reload());
  }

  joinClub() {
    this.companyService
      .clubStatus(this.company.id, 'join')
      .then(() => window.location.reload());
  }

  isPartOfTheGroup() {
    return this.user?.groups?.includes(this.company.Group.slug);
  }
}
