<div class="item-contents">
  <div
    class="image"
    style="background-image:url('/ProductImages/{{
      product.Product.id
    }}/thumbnail/{{ product.Product.slug }}image.jpg')"
  ></div>

  <div class="description">
    <h3>{{ product.Product.title }}</h3>

    <div class="item-container">
      <div
        class="items"
        *ngFor="let option of product.order_details.item.options | keyvalue"
      >
        <div class="item-row">
          {{ option.value.qty }} x {{ option.value.label }}
        </div>
        <div>
          {{
            (option.value.total == '0' && 'Free') ||
              formatTicketPrice(option.value.total)
          }}
        </div>
      </div>
      <div class="total">
        <strong> {{ formatTicketPrice(product.price_pence) }} </strong>
      </div>
    </div>
  </div>
  <div class="item-price">
    <p>
      <span class="full">{{ product.state | titlecase }} </span>
    </p>
  </div>
</div>
