<section class="terms-component" *ngIf="!loading">
  <div *ngIf="!hasReadTerms">
    <p>To continue, please agree to our Terms &amp; Conditions.</p>
    <button (click)="showTerms()">Agree to T's & C's</button>
  </div>
  <label class="small-12 columns" for="read-terms" *ngIf="hasReadTerms">
    <input id="read-terms" type="checkbox" [(ngModel)]="hasAgreedTerms" (change)="onAgreeChange()" />
    I have read and agree to the
    <a [href]="termsUrl" target="_blank">Terms &amp; Conditions</a>.
  </label>
</section>