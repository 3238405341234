<div class="page-loading" *ngIf="loading">
  <span>
      <span></span>
  </span>
</div>
<div class="my-ballots my-orders grid-layout">
  <div class="row clearfix max-width">
    <div class="small-12 columns account-row">
      <a (click)="navigate('/Users/my-profile')" title="">
        <span class="fa fa-fw fa-arrow-left"></span> Back to My Account
      </a>
    </div>
  </div>
  <div class="row clearfix max-width">
    <div class="small-12 columns header-row account-row">
      <h2>My Orders</h2>
    </div>
  </div>
  <div class="item" *ngFor="let entry of orders" [ngClass]="entry.state">
    <ticket-component
      *ngIf="entry.Product.ticket"
      [ticket]="entry"
    ></ticket-component>
    <product-component
      *ngIf="entry.Product.product"
      [product]="entry"
    ></product-component>
  </div>
</div>
