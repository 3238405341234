import '@angular/compiler';
import 'zone.js';
import 'reflect-metadata';
import {CommonModule} from '@angular/common';

import {
  CUSTOM_ELEMENTS_SCHEMA,
  importProvidersFrom,
  NgModule,
} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {UpgradeModule} from '@angular/upgrade/static';
import {MaterialModule} from './material.module';
import * as angular from 'angular';
import {setAngularJSGlobal} from '@angular/upgrade/static';
import {AppComponent} from './angular/appComponent/app.component';
import {AppRoutingModule} from './app-routing.module';
import {NoPathFound} from './angular/component/not-found/app.comonent';
import {FooterComponent} from './angular/component/footer/app.component';
import {AuthService, tokenGetter} from './angular/services/auth.service';
import {JwtModule} from '@auth0/angular-jwt';
import {ApiService} from './angular/services/api.service';
import {UserService} from './angular/services/user.service';
import {CommonService} from './angular/services/common.service';
import {SearchService} from './angular/services/search.service';

import {HttpClientModule} from '@angular/common/http';
import {HeaderModule} from './angular/component/header/module';
import {HomepageModule} from './angular/modules/homepage/module';
import {ListingModule} from './angular/modules/listing/module';
import {CompanyModule} from './angular/modules/company/module';
import {SharedModule} from './angular/modules/shared/module';
import {CategoryModule} from './angular/modules/category/module';
import {AccountModule} from './angular/modules/account/module';
import {CheckoutModule} from './angular/modules/checkout/module';
import {LoginModule} from './angular/modules/login/module';
import environment from '../src/environment/index.js';
import {LoginService} from './angular/services/login.service';
import {OldLeisure} from './angular/modules/old-leisure/module';
import {SearchModule} from './angular/modules/search/module';
import {OrdersModule} from './angular/modules/orders/module';

declare const WPCL: any;
const WorldPayCheckoutLibrary = typeof WPCL !== 'undefined' ? WPCL : {};

@NgModule({
  imports: [
    HttpClientModule,
    MaterialModule,
    HomepageModule,
    ListingModule,
    SharedModule,
    CompanyModule,
    CategoryModule,
    AccountModule,
    HeaderModule,
    BrowserModule,
    UpgradeModule,
    CommonModule,
    OldLeisure,
    SearchModule,
    CheckoutModule,
    AppRoutingModule,
    LoginModule,
    OrdersModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        disallowedRoutes: [`${environment.settings.leisureUrl}/json/Login`],
      },
    }),
  ],
  exports: [
    HeaderModule,
    HomepageModule,
    CategoryModule,
    AccountModule,
    ListingModule,
    SharedModule,
    CompanyModule,
    OldLeisure,
    CheckoutModule,
  ],
  declarations: [AppComponent, NoPathFound, FooterComponent],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    ApiService,
    UserService,
    CommonService,
    LoginService,
    SearchService,
    importProvidersFrom(HammerModule),
    {provide: 'WorldPayCheckoutLibrary', useValue: WorldPayCheckoutLibrary},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

setAngularJSGlobal(angular);
