<div class="modal">
    <section mat-dialog-title>
        <button mat-dialog-close mat-icon-button matTooltip="Close.">
            <mat-icon>close</mat-icon>
        </button>
        <h3>{{data.terms.title || 'Terms and Conditions'}}</h3>
    </section>
    <section mat-dialog-content>
        <ng-container *ngIf="data.terms">
            <div [innerHTML]="data.terms.body"></div>
        </ng-container>
    </section>
    <section align="end" mat-dialog-actions>
        <button mat-button mat-dialog-close matTooltip="Cancel and close." type="button">
            <mat-icon>cancel</mat-icon>
            Cancel
        </button>
        <button mat-button matTooltip="Agree to T's & C's." (click)="agreeToTerms()">
            Agree
        </button>
    </section>
</div>