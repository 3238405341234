<h1>Your Order Summary</h1>
<section class="overview-panel">
    <dl>
        <ng-container *ngIf="variant.displayDate">
            <dt>Date</dt>
            <dd>{{ variant.displayDate }}</dd>
        </ng-container>
        <ng-container *ngIf="variant.displayLocation">
            <dt>Location</dt>
            <dd>{{ variant.displayLocation || 'National' }}</dd>
        </ng-container>
    </dl>
</section>
<section class="options-panel">
    <div class="panel-title-section">
        <h2>{{ variant.title || 'Tickets' }}</h2>
        <p *ngIf="status.optionsSet" (click)="setOptionsPanel()" (keypress)="setOptionsPanel()">Edit</p>
    </div>
    <dl class="dl-horizontal flex-row" *ngIf="isOptionsNotEmpty()">
        <ng-container *ngFor="let option of summary.options | keyvalue">
            <dt>
                {{ option.value.label | titlecase }}
            </dt>
            <dd>
                <span>
                    {{ option.value.amount }} &#64; {{ prices[option.key] ? (prices[option.key] / 100 | currency :
                    'GBP') : 'Free' }} each
                </span>
                {{ prices[option.key] ? ((prices[option.key] * option.value.amount) / 100 | currency : 'GBP') : 'Free'
                }}
            </dd>
        </ng-container>
    </dl>
</section>
<section class="billing-panel">
    <div class="panel-title-section">
        <h2>Billing Address</h2>
        <p *ngIf="status.billingAddressSet" (click)="setPanel('billing')" (keypress)="setPanel('billing')">Edit</p>
    </div>
    <dl class="address flex-row">
        <dd>
            {{ summary.billingAddress.firstName }} {{
            summary.billingAddress.surname }}
        </dd>
        <dd>{{ summary.billingAddress.email }}</dd>
        <dd>{{ summary.billingAddress.phone }}</dd>
        <dd>{{ summary.billingAddress.address1 }}</dd>
        <dd>{{ summary.billingAddress.city }}</dd>
        <dd>{{ summary.billingAddress.county }}</dd>
        <dd>{{ summary.billingAddress.postCode }}</dd>
        <dd>{{ summary.billingAddress.country }}</dd>
    </dl>
</section>
<section class="shipping-panel" *ngIf="isDeliverable">
    <div class="panel-title-section">
        <h2>Shipping Address</h2>
        <p *ngIf="shippingAddressSet" (click)="setPanel('shipping')" (keypress)="setPanel('shipping')">Edit</p>
    </div>
    <dl class="address flex-row">
        <dd>
            {{ summary.shippingAddress.firstName }} {{
            summary.shippingAddress.surname }}
        </dd>
        <dd>{{ summary.shippingAddress.email }}</dd>
        <dd>{{ summary.shippingAddress.phone }}</dd>
        <dd>{{ summary.shippingAddress.address1 }}</dd>
        <dd>{{ summary.shippingAddress.city }}</dd>
        <dd>{{ summary.shippingAddress.county }}</dd>
        <dd>{{ summary.shippingAddress.postCode }}</dd>
        <dd>{{ summary.shippingAddress.country }}</dd>
    </dl>
</section>
<section class="total-panel">
    <dl class="panel-title-section">
        <h2>
            Total
            <span>{{ !!summary.total && (summary.total/100 | currency:'GBP':'symbol':'1.2-2') || '£0' }}</span>
        </h2>
    </dl>
</section>