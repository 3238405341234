import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {numericValidator} from '../../../validators/numeric-validator';

@Component({
  selector: 'forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: [],
})
export class ForgottenPasswordComponent implements OnInit {
  public form: FormGroup;
  public complete: boolean = false;
  public emailAddress: string = '';
  public loading: boolean = false;

  constructor(public fb: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      employeeNumber: [
        '',
        [Validators.required, Validators.minLength(8), numericValidator()],
      ],
    });
  }

  async onSubmit() {
    try {
      if (this.form.invalid) {
        return;
      }
      this.loading = true;
      this.emailAddress = this.form.getRawValue().email;
      const response = await this.userService.resetPasswordEmail(
        this.emailAddress,
        this.form.getRawValue().employeeNumber
      );
      if (response.body.status === true) {
        this.complete = true;
        this.loading = false;
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }
}
