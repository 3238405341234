import {Component, Inject} from '@angular/core';
import {UserService} from '../../services/user.service';
import {OrderService} from '../../services/order.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent {
  public user: any;
  public loading: boolean;
  private timer = null;
  private readonly iframeTTL = 1000 * 60 * 5;
  public variant: any;
  public product: any;

  public summary: any = {
    options: {},
    billingAddress: {},
    shippingAddress: {},
    total: 0,
  };

  public status = {
    optionsSet: false,
    billingAddressSet: false,
    shippingAddressSet: false,
  };

  public isDeliverable: boolean = false;

  public activePanel = new BehaviorSubject<string>('options');

  public isFree: any;

  constructor(
    @Inject('WorldPayCheckoutLibrary') private readonly worldPayCheckoutLibrary: any,
    private readonly userService: UserService,
    private readonly orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<CheckoutComponent>
  ) {
    this.setOptions = this.setOptions.bind(this);
    this.setBillingAddress = this.setBillingAddress.bind(this);
    this.setShippingAddress = this.setShippingAddress.bind(this);
  }

  ngOnInit() {
    this.loading = true;
    this.product = this.data.product;
    this.variant = this.data.variant;

    this.userService.User.subscribe(({User}) => {
      this.user = User;
    });
    this.isFree = !Object.values(this.variant.prices)
      .map((price: any) => price.price)
      .reduce((total, price) => total + price, 0);
    this.loading = false;
  }

  setPanel(panelName) {
    this.activePanel.next(panelName);
  }

  setOptions(options) {
    const {delivery = {}} = options || {};
    const {amount: hasDelivery = null} = delivery;

    this.isDeliverable = !!hasDelivery;

    const value = this.reduceOptions(options);
    this.setSummary('options', value);
    this.calculateTotal(options);

    const freeNonDeliverable = this.isFree && !this.isDeliverable,
      freeDeliverable = this.isFree && this.isDeliverable;

    switch (true) {
      case freeNonDeliverable:
        this.setBillingAddress();
        break;
      case freeDeliverable:
        this.setBillingAddress();
        this.activePanel.next('shipping');
        break;
      default:
        this.activePanel.next('billing');
        break;
    }
  }

  setSummary(key, value) {
    this.summary[key] = value;
    this.status[`${key}Set`] = true;
  }

  reduceOptions(options) {
    return Object.keys(options)
      .filter(slug => options[slug].amount)
      .reduce((tickets, slug) => {
        tickets[slug] = options[slug];

        return tickets;
      }, {});
  }

  calculateTotal(options) {
    this.summary.total = 0;
    Object.values(this.variant.prices).forEach((price: any) => {
      if (options.hasOwnProperty(price.slug)) {
        const numberOfTickets = options[price.slug].amount;
        this.summary.total += price.price * numberOfTickets;
      }
    });
  }

  setBillingAddress(billingAddress = {}) {
    const {first_name: firstName, last_name: surname, email} = this.user;

    this.setSummary('billingAddress', {
      firstName,
      surname,
      email,
      ...billingAddress,
    });

    switch (true) {
      case this.isDeliverable:
        this.activePanel.next('shipping');
        break;
      case this.isFree:
        this.doSomethingForFreebies();
        break;
      default:
        this.getIframeUrl();
        break;
    }
  }

  setShippingAddress(shippingAddress = {}) {
    const {first_name: firstName, last_name: surname, email} = this.user;

    this.setSummary('shippingAddress', {
      firstName,
      surname,
      email,
      ...shippingAddress,
    });

    !this.isFree ? this.getIframeUrl() : this.doSomethingForFreebies();
  }

  doSomethingForFreebies() {
    this.activePanel.next('free-purchase');
  }

  async registerOrder() {
    Object.keys(this.status).forEach(field => (this.status[field] = false));

    const options = Object.keys(this.summary.options).reduce(
      (options, slug) => {
        options[slug] = this.summary.options[slug].amount;

        return options;
      },
      {}
    );
    const order = {
      variantId: this.variant.id,
      options: options,
      billingAddress: this.summary.billingAddress,
      shippingAddress: this.summary.shippingAddress,
      priceVersion: this.variant.features.priceVersion,
    };

    this.activePanel.next(null);

    return await this.orderService.createOrder(order);
  }

  async getIframeUrl() {
    try {
      const result = await this.registerOrder();
      this.activePanel.next('card');
      const url = `${window.location.protocol}//${window.location.host}`;

      const customOptions = {
        iframeIntegrationId: 'libraryObject',
        iframeHelperURL: `${url}/worldpay-helper.html`,
        iframeBaseURL: `${url}`,
        type: 'iframe',
        target: 'worldpay-card-details',
        language: 'en',
        country: 'gb',
      };

      const options = {
        ...customOptions,
        url: result.redirectUrl,
        successURL: `${url}/order/${result.orderId}/success`, // AUTHORISED
        failureURL: `${url}/order/${result.orderId}/failure`, // REFUSED
        cancelURL: `${url}/order/${result.orderId}/cancel`, // Cancelled by the shopper
        errorURL: `${url}/order/${result.orderId}/error`, // ERROR
      };

      const WorldPayLibraryObject = new this.worldPayCheckoutLibrary.Library();
      WorldPayLibraryObject.setup(options);
      dispatchEvent(new Event('load'));

      this.setIframeTimer();
    } catch (error) {
      this.badResponse(error);
    }
  }

  trashIframe() {
    this.dialogRef.close();
  }

  setIframeTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => this.trashIframe(), this.iframeTTL);
  }

  badResponse(response) {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
