<div class="panel" [ngClass]="{'active-panel': activePanel.value === 'options'}">
    <div class="panel-heading">
        <h4 class="panel-title">
            {{ variant.title }}
            <span class="fa fa-check-square-o" [ngClass]="{'hide': !status.billingSet}"></span>
        </h4>
    </div>
    <div class="panel-body">
        <section [formGroup]="pickOptionsForm">
            <section class="prices">
                <div class="row">
                    <div class="small-12 columns">
                        <div class="row">
                            <div class="small-4 columns">
                                <label class="right inline" for="quantityInput">
                                    Quantity at {{ variant.price | currency:'GBP':'symbol':'1.2-2' }}
                                </label>
                            </div>
                            <div class="small-8 columns">
                                <input id="quantityInput" type="number" pattern="^\d+$" formControlName="amount" min="0" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="variant.deliveryMethod === 'post'">
                    <div class="small-12 columns">
                        <div class="row">
                            <div class="small-4 columns">
                                <label class="right inline" for="deliverySwitch">
                                    Delivery at {{variant.deliveryPrice == 0 ? 'Free' :
                                    (variant.deliveryPrice | currency:'GBP':'symbol':'1.2-2') }}
                                </label>
                            </div>
                            <div class="small-8 columns">
                                <div>
                                    <input id="deliverySwitch" type="checkbox" formControlName="deliveryAmount" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <p *ngIf="variant.ticketsPerPartner"
            [ngClass]="{'error' : total.items > variant.ticketsPerPartner, 'info' : total.items <= variant.ticketsPerPartner}">
            You are only allowed {{ variant.ticketsPerPartner }} item{{
            variant.ticketsPerPartner > 1 && 's' || '' }} per Partner
        </p>
        <p class="panel callout warning" *ngIf="showHurry || total.items > variant.stockQuantity">
            There {{ variant.stock_quantity == 1 && 'is' || 'are' }} only {{
            variant.stockQuantity }} left, be quick before they're
            all gone!
        </p>
        <checkout-terms [termsUrl]="tsAndCs" (onAgree)="terms = true" (onDisagree)="terms = false"
            [close]="close"></checkout-terms>
        <div class="full-width">
            <section class="price">
                {{ total.cost | currency:'GBP':'symbol':'1.2-2'}}
            </section>
            <button class="button accordion-toggle" (click)="addOptions()" [disabled]="disabled()">
                Add Items
            </button>
        </div>
    </div>
</div>