import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {passwordMatchValidator} from '../../../validators/password-match';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public form: FormGroup;
  public user: any = {};
  public loginHash: string;
  public loading: boolean = false;

  constructor(
    public fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]],
      },
      {validators: passwordMatchValidator}
    );
    this.route.paramMap.subscribe(async params => {
      this.loading = true;
      this.loginHash = params.get('loginHash');
      const response = await this.userService.getUserByLoginHash(
        this.loginHash
      );
      if (response.data) {
        this.user = response.data;
      }
      this.loading = false;
    });
  }

  async onSubmit() {
    try {
      if (this.form.invalid) {
        return;
      }
      this.loading = true;
      const response = await this.userService.resetPassword(
        this.loginHash,
        this.user.id,
        {
          email: this.user.email,
          firstName: this.user.first_name,
          id: this.user.id,
          lastName: this.user.last_name,
          password: this.form.getRawValue().password,
          passwordConfirm: this.form.getRawValue().passwordConfirm,
        }
      );
      if (response.status === true) {
        this.loading = false;
        this.router.navigate(['/login']);
      }
    } catch (error) {
      this.loading = false;
    }
  }
}
