import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';
@Injectable()
export class CompanyService {
  constructor(protected Api: ApiService) {}

  public load(slug) {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/companies/${slug}`
    ).then(({data}) => {
      return data;
    });
  }

  public clubStatus(companyId, status) {
    return this.Api.put(
      `${environment.settings.registeredAwsUrl}/clubs/${companyId}/${status}`,
      {}
    );
  }
}
