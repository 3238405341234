<section class="product-odney">
  <ng-container *ngFor="let item of product">
   {{product}}
      <button
      data-ng-click="$ctrl.pickOptions(item)"
      data-ng-disabled="!item.stock_quantity"
    >
      {{ item.stock_quantity && item.title || "Maximum membership for the year."
      }}
    </button>
  </ng-container>
</section>
