import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class CommonService {
  constructor(private router: Router) {}
  public navigate(path) {
    if(!window.location.pathname !== path) {
    this.router.navigate([path]).then(() => {
      window.location.reload();
    });
  }
  }
}