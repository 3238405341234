import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryComponent} from './app.component';
import { SharedModule } from '../shared/module';
@NgModule({
  declarations: [
    CategoryComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [CategoryComponent],
})
export class CategoryModule {}
