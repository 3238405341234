import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SearchService} from '../../../services/search.service';
import environment from '../../../../environment';


@Component({
  selector: 'search',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class SearchComponenet {
  public loading: boolean = true;
  public searchTerm: string;
  public listings;

  constructor(private route: ActivatedRoute, private search: SearchService) {
    this.loading = true;
    this.route.params.subscribe(params => {
      this.searchTerm = params.searchTerm;
    });
  }

  ngOnInit() {
    this.search.searchListing(this.searchTerm).then(data => {
      this.listings = data.map(listing => {return {...listing, imageUrl: `${environment.settings.leisureUrl}/CompanyImages/${listing.image_id}/thumbnail/${listing.image_name}`};});

      this.loading = false;
    });
  }
}
