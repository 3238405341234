import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CompanyComponent} from './app.component';
import {ListingService} from '../../services/listing.service';
import {CompanyService} from '../../services/company.service';
import {TicketsService} from '../../services/tickets.service';
import {ProductPriceComponent} from './product-price/app.component';
import {OdneyComponent} from './odney/app.component';
import {MaterialModule} from '../../../material.module';
import {ProductListPriceComponent} from './product-list-price/app.component';
import {CarouselModule} from '../../component/carousel/module';
import {SharedModule} from '../shared/module';
@NgModule({
  imports: [CommonModule, MaterialModule, CarouselModule, SharedModule],
  declarations: [
    CompanyComponent,
    ProductPriceComponent,
    OdneyComponent,
    ProductListPriceComponent,
  ],
  providers: [ListingService, CompanyService, TicketsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CompanyModule {}
