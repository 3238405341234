import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login.component';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../services/api.service';
import {RouterModule} from '@angular/router';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';
import {UserService} from '../../services/user.service';
import {CommonService} from '../../services/common.service';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {RegisterComponent} from './register/register.component';
import {MaterialModule} from '../../../material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
  ],
  declarations: [
    LoginComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
  ],
  providers: [AuthService, ApiService, UserService, CommonService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginModule {}
