<div class="page-loading" *ngIf="loading">
  <span>
      <span></span>
  </span>
</div>
<div *ngIf="company">
  <section class="product-contain" *ngIf="!company.Product.id">
    <image-carousel
      [items]="company.CompanyImage"
      [slug]="company.slug"
    ></image-carousel>
    <h1>{{ company.title }}</h1>

    <section class="container" *ngIf="company.group_id">
      <p *ngIf="isPartOfTheGroup()">
        <button class="secondary" (click)="leaveClub()">
          <span class="fa fa-fw fa-thumbs-down"></span> Leave
          {{ company.filter }}
        </button>
      </p>
      <div *ngIf="!isPartOfTheGroup()">
        <button (click)="joinClub()">
          <span class="fa fa-fw fa-thumbs-up"></span> Join {{ company.filter }}
        </button>
      </div>
    </section>

    <p [innerHTML]="company.description"></p>
    <ul>
      <li *ngIf="company.facebook_url">
        <a [href]="company.facebook_url"
          ><span class="fa fa-fw fa-facebook"></span
        ></a>
      </li>
      <li *ngIf="company.twitter_url">
        <a [href]="company.twitter_url"
          ><span class="fa fa-fw fa-twitter"></span
        ></a>
      </li>
    </ul>
  </section>
  <section class="product-contain" *ngIf="company.Product.id">
    <image-carousel
      [items]="company.Product.ProductImage"
      [slug]="company.slug"
    ></image-carousel>
    <section class="container">
      <h1>{{ company.title }}</h1>

      <div class="product-price">
        <div class="price full-width">
          <p>
            {{ company.Product.price_label }}
            {{ company.Product.price_label_value }}
          </p>
        </div>
        <p class="definition ng-binding">
          {{ company.Product.price_terms }}
        </p>
      </div>
      <extra-info
        *ngIf="company.Product.ProductExtraInfo"
        type="payment"
        [productInfo]="company.Product.ProductExtraInfo"
      >
      </extra-info>
    </section>
    <section class="container" *ngIf="company.group_id && user">
      <p *ngIf="isPartOfTheGroup()">
        <button class="secondary" (click)="leaveClub()">
          <span class="fa fa-fw fa-thumbs-down"></span> Leave
          {{ company.filter }}
        </button>
      </p>
      <div *ngIf="!isPartOfTheGroup()">
        <button (click)="joinClub()">
          <span class="fa fa-fw fa-thumbs-up"></span> Join
          {{ company.filter }}
        </button>
      </div>
    </section>

    <div class="container product-content">
      <div
        class="product-description"
        [innerHTML]="company.Product.description"
      ></div>

      <extra-info
        *ngIf="company.Product.ProductExtraInfo"
        type="product"
        [productInfo]="company.Product.ProductExtraInfo"
      >
      </extra-info>
    </div>
  </section>
<section
  *ngIf="showProducts()"
  class="grid-layout"
  data-infinite-scroll="$ctrl.nextPage()"
  data-infinite-scroll-distance="0"
>
  <div class="row clearfix max-width">
    <div class="small-12 columns">
      <div>
        <h1>{{ company.title }} listings</h1>
      </div>
    </div>
  </div>

<product-list [items]="company.listings"></product-list> 
  <!-- data-register-products-on-page="$ctrl.registerProductsOnPage(handler)"  -->
  </section>
</div>
