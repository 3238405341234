<div class="panel" *ngIf="shouldDisplay" [ngClass]="{'active-panel' : activePanel.value === title}">
    <div class="panel-heading">
        <h4 class="panel-title">
            {{ title }} Address
        </h4>
    </div>
    <div class="panel-body" *ngIf="user">
        <form class="form-horizontal">
            <select [(ngModel)]="addressIndex" required class="form-control" name="billingAddress">
                <option value="null">-- select address --</option>
                <option *ngFor="let address of user.UserAddress; let i = index" [value]="i">
                    {{ address.address.address1 }} - {{ address.address.postCode }}
                </option>
            </select>
            <div *ngIf="hasPhone()" class="alert-box secondary" (click)="editAddressModal(addressIndex)" (keydown)="editAddressModal(addressIndex)">
                Please edit your address to add a phone number
                <span class="fa fa-cog"></span>
            </div>
            <div class="full-width-buttons flex-row">
                <button class="button" (click)="editAddressModal('new')">
                    <span class="fa fa-plus-circle"></span> Add an Address
                </button>
                <button class="button accordion-toggle" type="button" *ngIf="deliverable && title === 'billing'"
                    [disabled]="!status()" (click)="address()">
                    Delivery Address <span class="fa fa-chevron-down"></span>
                </button>
                <button class="button accordion-toggle" type="button" *ngIf="!deliverable || title !== 'billing'"
                    [disabled]="!status()" (click)="address()">
                    Card Details <span class="fa fa-chevron-down"></span>
                </button>
            </div>
        </form>
    </div>
</div>