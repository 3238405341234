import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'product-list-price',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ProductListPriceComponent implements OnChanges {
  @Input() product;

  public priceString = '';

  public ngOnChanges() {
    const {label, value} = this.product.purchasable
      ? this.purchasable()
      : this.nonPurchasable();
    const priceValue = value !== null ? value : '';
    this.priceString = `<span>${label} ${priceValue}</span>`;
  }

  public purchasable() {
    const {from_price: fromPrice} = this.product;

    const label = 'Partner Price',
      value = fromPrice > 0 ? this.getPriceString(fromPrice) : 'Free';

    return {
      label,
      value,
    };
  }
  public nonPurchasable() {
    return {
      label: this.product.price_label || '&nbsp;',
      value: this.product.price_label_value,
    };
  }
  public getPriceString(price) {
    const fraction = !(price % 100) ? 0 : 2;

    return `£${(price / 100).toFixed(fraction)}`;
  }
}
